import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { creatorInputsShowRequest } from '../../api/creator_inputs';
import { contentViewResponsesByCreatorInputRequest } from '../../api/content_view_responses';
import { Container, Heading, Flex, Text, Skeleton, Dialog, Table, Strong } from '@radix-ui/themes';
import { MagnifyingGlassIcon, Cross1Icon } from '@radix-ui/react-icons';
import { MarkdownText } from '../../components/markdown_text';
import { EmptyList } from '../../components/empty_list';
import { Pagination } from '../../components/pagination';

function CreatorInputsShow() {
    const { id } = useParams();
    const [creatorInput, setCreatorInput] = useState({})
    const [contentViewResponses, setContentViewResponses] = useState([]);
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingContentViewResponses, setLoadingContentViewResponses] = useState(true);

    const fetchCreatorInputs = (id) => {
        setLoading(true);
        creatorInputsShowRequest(id).then((response) => {
            setCreatorInput(response.data);
            setLoading(false);
        })
    }

    const requestPerPage = useCallback((e, page) => {
        e.preventDefault();
        fetchContentViewResponses(id, page);
    }, [id]);

    const fetchContentViewResponses = (id, page = 1, perPage = 10) => {
        setLoadingContentViewResponses(true);
        contentViewResponsesByCreatorInputRequest(id, page, perPage).then((response) => {
            setContentViewResponses(response.data);
            setMeta(response.data.meta);
            setLoadingContentViewResponses(false);
        })
    }

    useEffect(() => {
        fetchCreatorInputs(id);
        fetchContentViewResponses(id);
    }, [id]);

    return (
        <Container size='4' p='2'>
            {loading && (
                <Skeleton>Carregando...</Skeleton>
            )}

            {!loading && (
                <>
                    <Heading>Detalhe da mémoria</Heading>

                    <Flex gap='4' direction='column' mt='4'>
                        <Strong>Título:</Strong>
                        <Text>{creatorInput.title}</Text>

                        <Strong>Domínios:</Strong>
                        <Table.Root>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {creatorInput.domains && creatorInput.domains.length > 0 && creatorInput.domains.map((domain, index) => (
                                    <Table.Row key={index} style={{ background: index % 2 === 0 ? 'var(--gray-1)' : 'var(--gray-2)' }}>
                                        <Table.RowHeaderCell>{domain.name}</Table.RowHeaderCell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>

                        <Strong>Versões das mémorias</Strong>
                        <Table.Root>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeaderCell>Criado em</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Agregado em</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Expira em</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {creatorInput.memories && creatorInput.memories.length > 0 && creatorInput.memories.map((memory, index) => (
                                    <Table.Row key={index} style={{ background: index % 2 === 0 ? 'var(--gray-1)' : 'var(--gray-2)' }}>
                                        <Table.RowHeaderCell>{new Date(memory.created_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</Table.RowHeaderCell>
                                        <Table.RowHeaderCell>{memory.embedded_at && new Date(memory.embedded_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</Table.RowHeaderCell>
                                        <Table.RowHeaderCell>{memory.expired_at && new Date(memory.expired_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</Table.RowHeaderCell>
                                        <Table.RowHeaderCell align='center'>
                                            <Dialog.Root>
                                                <Dialog.Trigger>
                                                    <Flex align='center'>
                                                        <MagnifyingGlassIcon width='20px' height='20px' />
                                                    </Flex>
                                                </Dialog.Trigger>

                                                <Dialog.Content maxWidth="90%">
                                                    <Flex style={{justifyContent: 'space-between'}}>
                                                        <Heading size='2'>Conteúdo da mémoria</Heading>
                                                        <Dialog.Close>
                                                            <Cross1Icon />
                                                        </Dialog.Close>
                                                    </Flex>
                                                    <MarkdownText text={memory.content} />
                                                </Dialog.Content>
                                            </Dialog.Root>
                                        </Table.RowHeaderCell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    </Flex>
                </>
            )}

            {loadingContentViewResponses && <Skeleton>Carregando...</Skeleton>}

            {!loadingContentViewResponses && (
                <>
                    <Heading mt='4'>Respostas geradas</Heading>

                    {contentViewResponses && contentViewResponses.data.length === 0 && (<EmptyList />)}
                    {contentViewResponses && contentViewResponses.data.length > 0 && contentViewResponses.data.map((contentViewResponse, index) => (
                        <Flex gap='4' direction='column' mt='2' p='2' key={index} style={{ background: index % 2 === 0 ? 'var(--gray-1)' : 'var(--gray-2)' }}>
                            <Strong>{index + 1}ª pergunta</Strong>
                            <Text>{contentViewResponse.content_view.text}</Text>

                            <Strong>Resposta gerada</Strong>
                            <MarkdownText text={contentViewResponse.text} />

                            <Strong>Criado em</Strong>
                            <Text>{new Date(contentViewResponse.created_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</Text>
                        </Flex>
                    ))}

                    <Pagination meta={meta} requestPerPage={requestPerPage} />
                </>
            )}
        </Container>
    );
}

export { CreatorInputsShow };