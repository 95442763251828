import ReactPlayer from 'react-player/lazy';
import DatePicker from "react-datepicker";
import React, { useEffect, useState, useCallback } from 'react';
import { Container, Heading, Text, Flex, Strong, Dialog, Avatar, AspectRatio, Select, Separator, Code, IconButton, Badge, Link } from '@radix-ui/themes';
import { Cross1Icon, CheckIcon, Cross2Icon, RocketIcon, MagicWandIcon, FileIcon, AvatarIcon } from '@radix-ui/react-icons';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { integrationEventsShowRequest, integrationEventDatesShowRequest } from '../../../api/integration_events';
import { Loading } from '../../../components/loading';
import { Pagination } from '../../../components/pagination';
import { MarkdownText } from '../../../components/markdown_text';
import { EmptyList } from '../../../components/empty_list';
import { Logo } from '../../../components/logo';

import "react-datepicker/dist/react-datepicker.css";

function EventsReceivedShow() {
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');

    const { user } = useAuth();
    const { chatId } = useParams();
    const [integrationEventsMeta, setIntegrationEventsMeta] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentStatus, setCurrentStatus] = useState(status || '');
    const [info, setInfo] = useState({});
    const [integrationEventsGrouped, setIntegrationEventsGrouped] = useState([]);
    const [dates, setDates] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());

    const toISOIgnoreTimezone = (inputDate) => {
        return inputDate.getFullYear() + "-" +
          ("0" + (inputDate.getMonth()+1)).slice(-2) + "-" +
          ("0" + inputDate.getDate()).slice(-2) + "T03:00:00";
    }

    const fetchIntegrationEvent = useCallback((e, date, page = 1) => {
        e && e.preventDefault();
        setLoading(true);

        integrationEventsShowRequest(user.id, chatId, currentStatus, date, page, 1000).then(response => {
            if (response.data.data.length === 0) {
                setIntegrationEventsGrouped([]);
                setIntegrationEventsMeta({});
                setLoading(false);
                return;
            }

            let messages = [];
            let responses = [];

            const firstMessageWithFromMeFalse = response.data.data.find(event => event.payload.messages[0].from_me === false);
            if (firstMessageWithFromMeFalse) {
                setInfo({
                    'chat_name': firstMessageWithFromMeFalse.payload.messages[0].chat_name,
                    'from_name': firstMessageWithFromMeFalse.payload.messages[0].from_name
                });
            }

            const responseSorted = response.data.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

            responseSorted.forEach(event => {
                let message = {};
                let response = {};

                event.payload.messages.forEach(event_message => {
                    if (event_message.type === 'action') {
                        if (event_message.action?.type === 'reaction') {
                            message = {
                                'id': event_message.id,
                                'text': event_message.action?.emoji,
                                'action': 'reaction',
                                'type': event_message.action?.type,
                                'status': event_message?.from_me ? event_message.status : event.status,
                                'from_me': event_message?.from_me,
                                'created_at': event_message?.timestamp,
                                'integration_event_id': event.integration_event_id,
                                'kind': event_message?.from_me ? 'answer' : 'question'
                            }
                        } else {
                            const targetId = event_message.action?.target;
                            messages.forEach((message) => {
                                if (message.id === targetId) {
                                    message.original_text = message.text;
                                    message.text = event_message.action?.edited_content?.body;
                                    message.action = event_message.action?.type;
                                    message.created_at = event_message?.timestamp;
                                }
                            });
                        }
                    } else if (event_message.type === 'text') {
                        message = {
                            'id': event_message.id,
                            'text': event_message.text?.body,
                            'type': event_message?.type,
                            'action': 'nothing',
                            'status': event_message?.from_me ? event_message.status : event.status,
                            'from_me': event_message?.from_me,
                            'created_at': event_message?.timestamp,
                            'integration_event_id': event.integration_event_id,
                            'kind': event_message?.from_me ? 'answer' : 'question'
                        }
                    } else if (event_message.type === 'voice') {
                        message = {
                            'id': event_message.id,
                            'text': event.extra_data?.voice_transcription,
                            'audio_file': event_message.voice?.link,
                            'type': event_message?.type,
                            'action': 'nothing',
                            'from_me': event_message?.from_me,
                            'status': event_message?.from_me ? event_message.status : event.status,
                            'created_at': event_message?.timestamp,
                            'integration_event_id': event.integration_event_id,
                            'kind': event_message?.from_me ? 'answer' : 'question'
                        }
                    } else if (event_message.type === 'image') {
                        message = {
                            'id': event_message.id,
                            'link': event_message.image?.link,
                            'preview': event_message.image?.preview,
                            'type': event_message?.type,
                            'action': 'nothing',
                            'from_me': event_message?.from_me,
                            'status': event_message?.from_me ? event_message.status : event.status,
                            'created_at': event_message?.timestamp,
                            'integration_event_id': event.integration_event_id,
                            'kind': event_message?.from_me ? 'answer' : 'question'
                        }
                    } else if (event_message.type === 'document') {
                        message = {
                            'id': event_message.id,
                            'link': event_message.document?.link,
                            'type': event_message?.type,
                            'action': 'nothing',
                            'from_me': event_message?.from_me,
                            'status': event_message?.from_me ? event_message.status : event.status,
                            'created_at': event_message?.timestamp,
                            'integration_event_id': event.integration_event_id,
                            'kind': event_message?.from_me ? 'answer' : 'question'
                        }
                    }

                    if (Object.keys(message).length !== 0) {
                        messages.push(message);
                    }
                });

                if (event.integration_responses.length > 0) {
                    event.integration_responses.forEach(integration_response => {
                        const canSkip = responses.some(response => response.integration_event_id === event.integration_event_id);
                        if (canSkip) {
                            return;
                        }

                        if (integration_response.content_view.responses.length === 0) {
                            return;
                        }

                        response = {
                            'status': event.status,
                            'text': integration_response.content_view.responses[0].text,
                            'type': 'text',
                            'from_me': false,
                            'created_at': new Date(integration_response.content_view.created_at).getTime() / 1000,
                            'integration_event_id': event.integration_event_id,
                            'kind': 'answer'
                        }
                    });

                    if (Object.keys(response).length !== 0) {
                        responses.push(response);
                    }
                }


            });


            const messagesAndResponses = messages.concat(responses);
            const messagesSorted = messagesAndResponses.sort((a, b) => new Date(a.created_at * 1000) - new Date(b.created_at * 1000));

            setIntegrationEventsGrouped(messagesSorted);
            setLoading(false);
        }).catch(error => {
            console.error(error);
        });
    }, [user.id, chatId, currentStatus]);

    const fetchIntegrationEventDates = useCallback((e, page) => {
        e && e.preventDefault();
        setLoading(true);

        integrationEventDatesShowRequest(user.id, chatId, currentStatus, page, 10).then(response => {
            setDates(response.data.data);

            setCurrentDate(new Date(response.data.data[0].date + 'T03:00:00').toISOString());
            fetchIntegrationEvent(null, response.data.data[0].date);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [user.id, chatId, currentStatus, fetchIntegrationEvent]);

    useEffect(() => {
        fetchIntegrationEventDates(null, 1)
    }, [fetchIntegrationEventDates]);

    const typeIcon = (event) => {
        switch (event.type) {
            case 'voice':
                return <ReactPlayer url={event.audio_file} height='40px' width='90%' controls />;
            default:
                return null;
        }
    }

    const statusColor = (status) => {
        let color = '';

        switch (status) {
            case 'completed':
            case 'approved':
            case 'sent':
            case 'delivered':
            case 'read':
                color = 'var(--green-2)';
                break;
            case 'needs_moderation':
                color = 'var(--yellow-2)';
                break;
            case 'failed':
            case 'rejected':
                color = 'var(--red-2)';
                break;
            default:
                color = 'var(--gray-2)';

        }

        return color;
    }

    const goToModeration = (e, id) => {
        e.preventDefault();
        navigate(`/moderacoes/${id}`);
    }

    const updateStatus = (status) => {
        setCurrentStatus(status);
        const searchParams = new URLSearchParams();
        searchParams.set('status', status);
        navigate(`/eventos-recebidos/${chatId}?${searchParams.toString()}`);
    }

    const filterHtml = () => {
        return <Flex direction='column' gap='2'>
            <Flex gap='1' align='center'>
                <Strong>Filtro:</Strong>
                <Flex align='center' gap='1' width='200px'>
                    <Select.Root value={currentStatus} onValueChange={updateStatus}>
                        <Select.Trigger className='Input' placeholder='Filtrar por status' />

                        <Select.Content>
                            <Select.Group>
                                <Select.Label>Selecione um status</Select.Label>
                                <Select.Item id='completed' value='completed'>Completados</Select.Item>
                                <Select.Item id='approved' value='approved'>Aprovados</Select.Item>
                                <Select.Item id='rejected' value='rejected'>Rejeitados</Select.Item>
                                <Select.Item id='failed' value='failed'>Falhados</Select.Item>
                                <Select.Item id='needs_moderation' value='needs_moderation'>Precisam de moderação</Select.Item>
                                <Select.Item id='all' value='all'>Todos</Select.Item>
                            </Select.Group>
                        </Select.Content>
                    </Select.Root>
                    {status !== '' && <Link onClick={(e) => updateStatus('')} size='2'>Limpar</Link>}
                </Flex>
            </Flex>

            <Flex gap='1' align='center' style={{alignItems: 'center'}}>
                <Strong>Data:</Strong>
                {currentDate && <DatePicker
                    highlightDates={dates.map(date => new Date(date.date + 'T00:00:00'))}
                    placeholderText={currentDate}
                    selected={currentDate}
                    onChange={(date) => {
                        const dateStr = toISOIgnoreTimezone(date);
                        fetchIntegrationEvent(null, dateStr.replace('T03:00:00', ''));
                        setCurrentDate(dateStr);
                    }}
                    includeDates={dates.map(date => toISOIgnoreTimezone(new Date(date.date + 'T00:00:00')))}
                    customInput={
                        <input className='Input' type='text' value={currentDate} readOnly />
                    }
                />}
            </Flex>
        </Flex>
    }

    const questionHtml = (message, index) => {
        return (
            <Flex key={index} gap='1' p='2' my='1' align='center' width='100%' style={{
                alignItems: 'flex-end',
                borderRadius: 'var(--space-2)',
                justifyContent: 'space-between',
                background: 'var(--gray-2)'
            }}>
                {(message.type === 'text' || message.type === 'voice' || message.action === 'reaction') && <>
                    <Flex gap='1' align='center' direction='column' style={{alignItems: 'flex-start'}}>
                        {message.action === 'edit' && <Text style={{textDecoration: 'line-through'}} color='indigo' size='2' mr='1'>{message.original_text}</Text>}
                        {message.action === 'delete' && <Text style={{textDecoration: 'line-through'}} color='crimson'>{message.original_text}</Text>}
                        {message.text && <MarkdownText text={message.text} />}
                        {typeIcon(message)}
                    </Flex>
                </>}
                {message.type === 'image' && (
                    <Dialog.Root>
                        <Dialog.Trigger>
                            <Avatar src={message.preview}/>
                        </Dialog.Trigger>
                        <Dialog.Content size='1'>
                            <Dialog.Close>
                                <Cross1Icon width='20px' height='20px' />
                            </Dialog.Close>
                            <AspectRatio ratio={2 / 3}>
                                <img src={message.link} alt='Imagem' width='100%' />
                            </AspectRatio>
                        </Dialog.Content>
                    </Dialog.Root>
                )}

                {message.type === 'document' && (
                    <Flex gap='1' py='2' align='center' direction='column' style={{alignItems: 'flex-start'}}>
                        <Link href={message.link} target='_blank' size='3'>
                            <Flex align='center' gap='1'>
                                <FileIcon width='20px' height='20px' />
                                <Text>Documento enviado</Text>
                            </Flex>
                        </Link>
                    </Flex>
                )}

                <Flex align='center' gap='1'>
                    {message.action === 'edit' && <Code color='indigo'>editada</Code>}
                    {message.action === 'delete' && <Code color="crimson">deletada</Code>}
                    {message.status === 'skip' && <Code color='gray'>pausada</Code>}
                    {message.status === 'stopped' && <Code color='red'>transbordada</Code>}
                    <Text ml='2'>
                        {new Date(message.created_at * 1000).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit',  hour: '2-digit', minute: '2-digit' })}
                    </Text>
                </Flex>
            </Flex>
        )
    }

    const answerHtml = (event, index, withCreatorAvatar = false) => {
        return (
            <Flex gap='1' key={index} align='center' my='2' style={{alignItems: 'flex-start', justifyContent: 'space-between'}}>
                <Flex align='center' p='2' gap='1' style={{alignItems: 'flex-end', borderRadius: 'var(--space-2)', background: statusColor(event?.status)}}>
                    {(event.type === 'text' || event.type === 'voice' || event.action === 'reaction') && <>
                        <Flex direction='column'>
                            <MarkdownText text={event?.text} />
                        </Flex>
                    </>}

                    {event.type === 'image' && (
                        <Dialog.Root>
                            <Dialog.Trigger>
                                <Avatar src={event.preview}/>
                            </Dialog.Trigger>
                            <Dialog.Content size='1'>
                                <Dialog.Close>
                                    <Cross1Icon width='20px' height='20px' />
                                </Dialog.Close>
                                <AspectRatio ratio={2 / 3}>
                                    <img src={event.link} alt='Imagem' width='100%' />
                                </AspectRatio>
                            </Dialog.Content>
                        </Dialog.Root>
                    )}

                    {event.type === 'document' && (
                        <Flex gap='1' py='2' align='center' direction='column' style={{alignItems: 'flex-start'}}>
                            <Link href={event.link} target='_blank' size='3'>
                                <Flex align='center' gap='1'>
                                    <FileIcon width='20px' height='20px' />
                                    <Text>Documento enviado</Text>
                                </Flex>
                            </Link>
                        </Flex>
                    )}

                    <Text ml='2'>
                        {new Date(event.created_at * 1000).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' })}
                    </Text>
                </Flex>

                <Flex gap='1' align='center' direction='column'>
                    {withCreatorAvatar && <Avatar variant="solid" color="gray" fallback={<AvatarIcon width='30px' height='30px' />} src={user.image} radius='full'/>}

                    {!withCreatorAvatar && <Avatar variant="solid" color="indigo" highContrast fallback={<Logo withText={false} clicable={false} />} radius='full' />}

                    {!withCreatorAvatar && event?.from_me === false && event?.status === 'needs_moderation' && (
                        <Flex align='center' justify='flex-end'>
                            <IconButton variant="solid" color='yellow' style={{cursor: 'pointer'}} onClick={(e) => goToModeration(e, event.integration_event_id)}>
                                <MagicWandIcon width='20px' height='20px' />
                            </IconButton>
                        </Flex>
                    )}

                    {!withCreatorAvatar && event?.status === 'approved' && <CheckIcon color='green' width='20px' height='20px' />}
                    {!withCreatorAvatar && event?.status === 'rejected' && <Cross2Icon color='red' width='20px' height='20px' />}
                    {!withCreatorAvatar && event?.status === 'skip' && <Cross2Icon color='red' width='20px' height='20px' />}
                    {!withCreatorAvatar && (
                        event?.status === 'completed' ||
                        event?.status === 'delivered' ||
                        event?.status === 'sent' ||
                        event?.status === 'read'
                     ) && <RocketIcon color='indigo' width='20px' height='20px' />}
                </Flex>
            </Flex>
        )
    }

    return (
        <Container size='4' p='2'>
            <Heading>Eventos recebidos</Heading>

            {loading && <Loading />}
            {!loading && (
                <Flex mt='4' direction='column' gap='4'>
                    <Flex gap='1'>
                        <Strong>ID:</Strong>
                        <Text>{chatId}</Text>
                    </Flex>

                    {Object.keys(info).length !== 0 && <Flex gap='1'>
                        <Strong>Nome:</Strong>
                        <Text>{info.chat_name || info.from_name}</Text>
                    </Flex>}

                    {filterHtml()}
                    <Separator size='4' m='0' />

                    <Flex gap='1' align='center' justify='center'>
                        <Badge size='2'>{new Date(currentDate).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</Badge>
                    </Flex>

                    {integrationEventsGrouped.length !== 0 && <>
                        {integrationEventsGrouped.map((message, index) =>
                            <Flex key={index} direction='column' pr='1' style={{alignItems: 'flex-end'}}>
                                {message.kind === 'question' && questionHtml(message, index)}
                                {message.kind === 'answer' && answerHtml(message, index)}
                            </Flex>
                        )}

                        <Pagination meta={integrationEventsMeta} requestPerPage={fetchIntegrationEvent} />
                    </>}

                    {integrationEventsGrouped.length === 0 && <EmptyList />}
                </Flex>
            )}
        </Container>
    );
}

export { EventsReceivedShow };