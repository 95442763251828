import React, { useEffect, useState, useCallback } from 'react';
import { Container, Heading, Flex, Button, Table, Box, DropdownMenu } from '@radix-ui/themes';
import { Pencil1Icon, MagnifyingGlassIcon, TrashIcon, PlayIcon, PauseIcon, PlusIcon } from '@radix-ui/react-icons';
import { Link, useNavigate } from 'react-router-dom';
import { Loading } from '../../components/loading';
import { EmptyList } from '../../components/empty_list';
import { Pagination } from '../../components/pagination';
import { ButtonWithAlert } from '../../components/button_with_alert';
import { ToastInfo } from '../../components/toast';
import { scheduleIntegrationIndexRequest, scheduleIntegrationDeleteRequest, scheduleIntegrationActivateRequest, scheduleIntegrationDeactivateRequest } from '../../api/schedule_integrations';
import { useAuth } from '../../hooks/auth';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

function ScheduleIntegrationIndex() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [scheduleIntegrations, setScheduleIntegrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const perPage = 10;
    const [meta, setMeta] = useState({});

    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const fetchDatas = useCallback((e, page) => {
        e.preventDefault();
        setLoading(true);

        scheduleIntegrationIndexRequest(user.id, page, perPage).then((response) => {
            setScheduleIntegrations(response.data.data);
            setMeta(response.data.meta);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
        });
    }, [user]);

    useEffect(() => {
        fetchDatas({ preventDefault: () => {} }, 1);
    }, [fetchDatas]);

    const dayOfWeek = (day) => {
        switch (day) {
            case '0':
                return 'Domingo';
            case '1':
                return 'Segunda-feira';
            case '2':
                return 'Terça-feira';
            case '3':
                return 'Quarta-feira';
            case '4':
                return 'Quinta-feira';
            case '5':
                return 'Sexta-feira';
            case '6':
                return 'Sábado';
            default:
                return '';
        }
    }

    const handleFrequency = (cronStatement) => {
        const frequency = cronStatement.split(' ');
        const hour = frequency[1] === '*' ? 'de hora em hora' : `às ${frequency[1]}:00`;
        const days = frequency[4] === '*' ? 'Todos os dias' : `Todas as ${dayOfWeek(frequency[4])}`;
        return `${days}, ${hour}`;
    }

    const goToEdit = (e, id) => {
        e.preventDefault();
        navigate(`/acoes/${id}/editar`);
    }

    const goToShow = (e, id) => {
        e.preventDefault();
        navigate(`/acoes/${id}`);
    }

    const deleteScheduleIntegration = (_, id) => {
        scheduleIntegrationDeleteRequest(id).then(_ => {
            fetchDatas({ preventDefault: () => {} }, 1);
        }).catch(_ => {
            setToastOpen(true);
            setToastMessage('Esta ação não pode ser excluída, pois está sendo utilizada em alguma(s) integração(ões).');
        });
    }

    const activeRequest = (e, id) => {
        e.preventDefault();
        scheduleIntegrationActivateRequest(id).then(_ => {
            fetchDatas({ preventDefault: () => {} }, 1);
        }).catch(error => {
            console.log(error);
        });
    }

    const deactiveRequest = (e, id) => {
        e.preventDefault();
        scheduleIntegrationDeactivateRequest(id).then(_ => {
            fetchDatas({ preventDefault: () => {} }, 1);
        }).catch(error => {
            console.log(error);
        });
    }

    const activeButton = (id) => {
        return (
            <Button size="1" mr='2' onClick={(e) => activeRequest(e, id)}>
                <PlayIcon width={20} height={20} />
            </Button>
        );
    }

    const deactiveButton = (id) => {
        return (
            <Button size="1" mr='2' onClick={(e) => deactiveRequest(e, id)}>
                <PauseIcon width={20} height={20} />
            </Button>
        );
    }

    const handleStatus = (id, status) => {
        switch (status) {
            case 'pending':
                return activeButton(id);
            case 'active':
                return deactiveButton(id);
            case 'inactive':
                return activeButton(id);
            default:
                return '';
        }
    }

    const convertDurationToDate = (durationStr) => {
        let durationArr = durationStr.split(', ');

        let day = 0
        if (durationArr.length === 1) {
            durationArr.unshift('0');
        } else {
            day = durationArr[0];
        }

        const time = durationArr[1];

        const [hours, minutes, secondsWithMs] = time.split(':');
        const seconds = secondsWithMs.split('.')[0];
        const milliseconds = secondsWithMs.split('.')[1];

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + parseInt(day));
        currentDate.setHours(currentDate.getHours() + parseInt(hours));
        currentDate.setMinutes(currentDate.getMinutes() + parseInt(minutes));
        currentDate.setSeconds(currentDate.getSeconds() + parseInt(seconds));
        currentDate.setMilliseconds(currentDate.getMilliseconds() + parseInt(milliseconds) / 1000);
        return currentDate;
    }

    return (
        <Container size='4' maxWidth='100%' p='2'>
            <Flex gap='2' my='4'>
                <Heading >Minhas ações</Heading>
                <Button size="2" ml='auto' asChild>
                    <Link to='/acoes/nova'>
                        <Box display={displayOnlyLaptopsAndDesktops()}>
                            Criar nova ação
                        </Box>

                        <Flex display={displayOnlyMobiles(true)}>
                            <PlusIcon />
                        </Flex>
                    </Link>
                </Button>
            </Flex>

            {loading && <Loading />}
            {!loading && scheduleIntegrations.length === 0 && <EmptyList />}
            {!loading && scheduleIntegrations.length > 0 && <>
                <Box display={displayOnlyLaptopsAndDesktops()}>
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Ação</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Integração</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Destino</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Frequência</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Próxima execução</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {!loading && scheduleIntegrations.map((scheduleIntegration) => (
                                <Table.Row key={scheduleIntegration.id}>
                                    <Table.RowHeaderCell>{JSON.parse(scheduleIntegration.configurations).text}</Table.RowHeaderCell>
                                    <Table.Cell>{scheduleIntegration.integration.type}</Table.Cell>
                                    <Table.Cell>
                                        {scheduleIntegration.integration.type === 'whatsapp' ? JSON.parse(scheduleIntegration.configurations).integration_event_identifier : ''}
                                    </Table.Cell>
                                    <Table.Cell>{handleFrequency(scheduleIntegration.cron)}</Table.Cell>
                                    <Table.Cell>{scheduleIntegration.remaining_estimate && convertDurationToDate(scheduleIntegration.remaining_estimate).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</Table.Cell>
                                    <Table.Cell>
                                        {handleStatus(scheduleIntegration.id, scheduleIntegration.status)}

                                        <Button size="1" mr='2' onClick={(e) => goToEdit(e, scheduleIntegration.id)}>
                                            <Pencil1Icon width={20} height={20} />
                                        </Button>

                                        <Button size="1" mr='2' onClick={(e) => goToShow(e, scheduleIntegration.id)}>
                                            <MagnifyingGlassIcon width={20} height={20} />
                                        </Button>

                                        <ToastInfo
                                            children={
                                                <ButtonWithAlert
                                                    callback={deleteScheduleIntegration}
                                                    callbackParams={scheduleIntegration.id}
                                                    children={
                                                        <Button size="1" mr='2'>
                                                            <TrashIcon width={20} height={20} />
                                                        </Button>
                                                    }
                                                />
                                            }
                                            toastOpen={toastOpen}
                                            setToastOpen={setToastOpen}
                                            message={toastMessage}
                                        />

                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>

                <Box display={displayOnlyMobiles()}>
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Ação</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {!loading && scheduleIntegrations.map((scheduleIntegration) => (
                                <Table.Row key={scheduleIntegration.id}>
                                    <Table.RowHeaderCell>{JSON.parse(scheduleIntegration.configurations).text}</Table.RowHeaderCell>
                                    <Table.Cell>{handleStatus(scheduleIntegration.id, scheduleIntegration.status)}</Table.Cell>
                                    <Table.Cell>
                                        <DropdownMenu.Root>
                                            <DropdownMenu.Trigger>
                                                <Button variant='soft'>
                                                    ...
                                                    <DropdownMenu.TriggerIcon />
                                                </Button>
                                            </DropdownMenu.Trigger>

                                            <DropdownMenu.Content>
                                                <DropdownMenu.Item onSelect={(e) => goToEdit(e, scheduleIntegration.id)}>
                                                    <Pencil1Icon width={20} height={20} />
                                                    Editar
                                                </DropdownMenu.Item>

                                                <DropdownMenu.Item onSelect={(e) => goToShow(e, scheduleIntegration.id)}>
                                                    <MagnifyingGlassIcon width={20} height={20} />
                                                    Visualizar
                                                </DropdownMenu.Item>

                                                <ToastInfo
                                                    children={
                                                        <ButtonWithAlert
                                                            callback={deleteScheduleIntegration}
                                                            callbackParams={scheduleIntegration.id}
                                                            children={
                                                                <DropdownMenu.Item>
                                                                    <TrashIcon width={20} height={20} />
                                                                    Excluir
                                                                </DropdownMenu.Item>
                                                            }
                                                        />
                                                    }
                                                    toastOpen={toastOpen}
                                                    setToastOpen={setToastOpen}
                                                    message={toastMessage}
                                                />
                                            </DropdownMenu.Content>
                                        </DropdownMenu.Root>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>
            </>}

            <Pagination meta={meta} requestPerPage={fetchDatas} />
        </Container>
    );
}

export { ScheduleIntegrationIndex }