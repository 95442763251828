import { useCallback, useEffect, useState } from 'react';
import { Container, Flex, Heading, Table, Box, Button, Switch, Spinner, Badge, SegmentedControl } from '@radix-ui/themes';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { Loading } from '../../../components/loading';
import { LockClosedIcon, LockOpen1Icon, MagnifyingGlassIcon, MagicWandIcon } from '@radix-ui/react-icons';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../../contexts/responsive_tools';
import { EmptyList } from '../../../components/empty_list';
import { Pagination } from '../../../components/pagination';

import { integrationEventBlacklistsCreateRequest, integrationEventBlacklistsDeleteRequest } from '../../../api/integration_event_blacklists';
import { integrationEventsIndexRequest, integrationEventsToPendingModerationsByChatIdsRequest } from '../../../api/integration_events';

function EventsReceivedIndex() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');

    const { user, accountConfiguration, updateAccountConfiguration } = useAuth();
    const [pendingModerations, setPendingModerations] = useState([]);
    const [integrationEvents, setIntegrationEvents] = useState([]);
    const [integrationEventsMeta, setIntegrationEventsMeta] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingPendingModerations, setLoadingPendingModerations] = useState(true);
    const [handleBlacklistLoading, setHandleBlacklistLoading] = useState(false);
    const [status, setStatus] = useState(accountConfiguration.received_events_status_list || 'needs_moderation');

    const fetchIntegrationEventsToPendingModerationsByChatIds = useCallback((events) => {
        setPendingModerations([]);
        setLoadingPendingModerations(true);

        const chatIds = events.map(integrationEvent => integrationEvent.payload.messages[0].chat_id);
        integrationEventsToPendingModerationsByChatIdsRequest(user.id, chatIds).then(response => {
            const froms = {}

            response.data.data.forEach(pendingModeration => {
                const from = pendingModeration.payload.messages[0].from;
                if (!froms[from]) {
                    froms[from] = 1;
                } else {
                    froms[from]++;
                }
            });

            setPendingModerations(froms);
            setLoadingPendingModerations(false);
        }).catch(error => {
            console.error(error);
            setLoadingPendingModerations(false);
        });
    }, [user.id]);

    const fetchIntegrationEvents = useCallback((e, page, attr = {}) => {
        e && e.preventDefault();
        setLoading(true);
        setLoadingPendingModerations(true);
        setHandleBlacklistLoading(false);
        setIntegrationEvents([]);
        setIntegrationEventsMeta({});

        integrationEventsIndexRequest(user.id, attr, page, 10).then(response => {
            setIntegrationEvents(response.data.data);
            setIntegrationEventsMeta(response.data.meta);
            setLoading(false);

            fetchIntegrationEventsToPendingModerationsByChatIds(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    }, [user.id, fetchIntegrationEventsToPendingModerationsByChatIds]);

    const updateAccountPreferences = (status) => {
        updateAccountConfiguration({
            ...accountConfiguration,
            received_events_status_list: status
        });
    }

    const addIdentifierOnBlacklist = (integrationEvent) => {
        integrationEventBlacklistsCreateRequest({
            integration_id: integrationEvent.integration.id,
            identifier: integrationEvent.payload.messages[0].chat_id,
            interval: 'fixed'
        }).then(_ => {
            integrationEvent.blacklisted = true;
            setIntegrationEvents([...integrationEvents]);
            setHandleBlacklistLoading(false);
        }).catch(error => {
            console.error(error);
            setHandleBlacklistLoading(false);
        });
    }

    const removeIdentifierOnBlacklist = (integrationEvent) => {
        integrationEventBlacklistsDeleteRequest({
            account_id: user.id,
            integration_id: integrationEvent.integration.id,
            identifier: integrationEvent.payload.messages[0].chat_id
        }).then(_ => {
            integrationEvent.blacklisted = false;
            setIntegrationEvents([...integrationEvents]);
            setHandleBlacklistLoading(false);
        }).catch(error => {
            console.error(error);
            setHandleBlacklistLoading(false);
        });
    }

    const handleBlacklist = (e, integrationEvent) => {
        setHandleBlacklistLoading(true);
        const state = e.target.getAttribute('data-state') === 'checked' ?  false : true;
        if (state) {
            addIdentifierOnBlacklist(integrationEvent);
        } else {
            removeIdentifierOnBlacklist(integrationEvent);
        }
    }

    useEffect(() => {
        fetchIntegrationEvents(null, page ? page : 1, { status: status });
    }, [fetchIntegrationEvents, status, page]);

    const goToShow = (e, id, status = '') => {
        e.preventDefault();
        navigate(`/eventos-recebidos/${id}?status=${status}`);
    }

    const filterStatusHandler = (status) => {
        updateAccountPreferences(status);
        setStatus(status);
    }

    return (
        <Container size='4' maxWidth='100%' p='2'>
            <Flex gap='2' my='6'>
                <Heading>Eventos recebidos</Heading>
            </Flex>

            <Flex gap='2' my='2'>
                <SegmentedControl.Root defaultValue={status} onValueChange={filterStatusHandler}>
                    <SegmentedControl.Item value="needs_moderation">Com moderação</SegmentedControl.Item>
                    <SegmentedControl.Item value="all">Todos</SegmentedControl.Item>
                </SegmentedControl.Root>
            </Flex>

            {loading && <Loading />}
            {(!loading && integrationEvents.length === 0 && <EmptyList />)}
            {(!loading && integrationEvents.length > 0 && <>
                <Box display={displayOnlyLaptopsAndDesktops()}>
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Integração</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Ponto</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Atualizado em</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {integrationEvents.map(integrationEvent => (
                                <Table.Row key={integrationEvent.id}>
                                    <Table.Cell>{integrationEvent.integration.name}</Table.Cell>
                                    <Table.Cell>{integrationEvent.payload.messages[0].chat_name || integrationEvent.payload.messages[0].from_name}({integrationEvent.payload.messages[0].from})</Table.Cell>
                                    <Table.Cell>{new Date(integrationEvent.created_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</Table.Cell>
                                    <Table.Cell>
                                        <Flex align='center'>
                                            <Flex gap='1' align='center'>
                                                {handleBlacklistLoading && <Spinner />}
                                                {!handleBlacklistLoading && integrationEvent.blacklisted && <LockClosedIcon color='crimson' width={20} height={20} />}
                                                {!handleBlacklistLoading && !integrationEvent.blacklisted && <LockOpen1Icon width={20} height={20} />}
                                                <Switch color="crimson" disabled={handleBlacklistLoading} defaultChecked={integrationEvent.blacklisted} onClick={(e) => { handleBlacklist(e, integrationEvent) }} size="3" />
                                            </Flex>

                                            <Button size="1" ml='2' onClick={(e) => goToShow(e, integrationEvent.payload.messages[0].from)}>
                                                <MagnifyingGlassIcon width={20} height={20} />
                                            </Button>

                                            {loadingPendingModerations && <Button size="1" ml='2' variant="soft" style={{backgroundColor: 'white'}}>
                                                <Flex gap='1' align='center'>
                                                    <Spinner mx='3' size='2' />
                                                </Flex>
                                            </Button>}
                                            {!loadingPendingModerations && pendingModerations[integrationEvent.payload.messages[0].from] && <Button size="1" ml='2' variant="soft" color='orange' onClick={(e) => goToShow(e, integrationEvent.payload.messages[0].from, 'needs_moderation')}>
                                                <Flex gap='1' align='center'>
                                                    <Badge color='yellow' style={{ cursor: 'pointer' }}>
                                                        {pendingModerations[integrationEvent.payload.messages[0].from] || 0}
                                                    </Badge>
                                                    <MagicWandIcon />
                                                </Flex>
                                            </Button>}
                                        </Flex>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>

                <Box display={displayOnlyMobiles()}>
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Ponto</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {integrationEvents.map(integrationEvent => (
                                <Table.Row key={integrationEvent.id}>
                                    <Table.Cell>{integrationEvent.payload.messages[0].from_name}({integrationEvent.payload.messages[0].from})</Table.Cell>
                                    <Table.Cell>
                                        <Flex>
                                            <Flex gap='1' align='center'>
                                                {handleBlacklistLoading && <Spinner />}
                                                {!handleBlacklistLoading && integrationEvent.blacklisted && <LockClosedIcon color='crimson' width={15} height={15} />}
                                                {!handleBlacklistLoading && !integrationEvent.blacklisted && <LockOpen1Icon width={15} height={15} />}
                                                <Switch color="crimson" disabled={handleBlacklistLoading} defaultChecked={integrationEvent.blacklisted} onClick={(e) => { handleBlacklist(e, integrationEvent) }} size="1" />
                                            </Flex>

                                            <Button size="1" ml='2' onClick={(e) => goToShow(e, integrationEvent.payload.messages[0].from)}>
                                                <MagnifyingGlassIcon width={20} height={20} />
                                            </Button>

                                            {loadingPendingModerations && <Button size="1" ml='2' variant="soft" style={{backgroundColor: 'white'}}>
                                                <Flex gap='1' align='center'>
                                                    <Spinner mx='3' size='2' />
                                                </Flex>
                                            </Button>}
                                            {!loadingPendingModerations && pendingModerations[integrationEvent.payload.messages[0].from] && <Button size="1" ml='2' variant="soft" color='orange' onClick={(e) => goToShow(e, integrationEvent.payload.messages[0].from, 'needs_moderation')}>
                                                <Flex gap='1' align='center'>
                                                    <Badge color='yellow' style={{ cursor: 'pointer' }}>
                                                        {pendingModerations[integrationEvent.payload.messages[0].from] || 0}
                                                    </Badge>
                                                    <MagicWandIcon />
                                                </Flex>
                                            </Button>}
                                        </Flex>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>

                <Pagination meta={integrationEventsMeta} requestPerPage={fetchIntegrationEvents} requestPerPageArgs={{ status: status }} />
            </>)}
        </Container>
    );
}

export { EventsReceivedIndex };