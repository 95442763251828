import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Heading } from '@radix-ui/themes';

import { dataIntegrationShowRequest } from '../../api/data_integrations';

import { Loading } from '../../components/loading';
import { EmptyList } from '../../components/empty_list';

import { WebhookShow } from './types/webhook_show';
import { WhatsappShow } from './types/whatsapp_show';

function DataIntegrationsShow() {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [dataIntegration, setDataIntegration] = useState(null);

    const fetchDataIntegration = useCallback(async () => {
        setLoading(true);
        dataIntegrationShowRequest(id).then(response => {
            setDataIntegration(response.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        });
    }, [id]);

    useEffect(() => {
        fetchDataIntegration();
    }, [fetchDataIntegration]);

    return (
        <Container size='4' p='2'>
            <Heading>Detalhe da integração</Heading>

            {loading && <Loading />}
            {!loading && !dataIntegration && <EmptyList />}
            {!loading && dataIntegration && dataIntegration.schedule_integration.integration.type === 'webhook' && <WebhookShow dataIntegration={dataIntegration} id={id} />}
            {!loading && dataIntegration && dataIntegration.schedule_integration.integration.type === 'whatsapp' && <WhatsappShow dataIntegration={dataIntegration} id={id} />}
        </Container>
    );
}

export { DataIntegrationsShow }