import { useCallback, useEffect, useState } from 'react';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Box, Button, Callout, Flex, Text, Select, HoverCard, Heading, Link as LinkUI, Separator } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import * as Form from '@radix-ui/react-form';
import { useAuth } from '../../hooks/auth';

import { integrationIndexRequest } from '../../api/integrations';
import { profileShowDomainRequest } from '../../api/profiles';
import { accountByAccountRequest } from '../../api/accounts_domains';

function ScheduleIntegrationsForm(props) {
    const { user } = useAuth();
    const [integrations, setIntegrations] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [domains, setDomains] = useState([]);
    const [formData, setFormData] = useState({});
    const [hasChatIntegration, setHasChatIntegration] = useState(false);
    const { errors, loading, handleSubmit, integration } = props;
    const daysOfWeek = [
        {
            name: 'Todos os dias',
            value: '*'
        },
        {
            name: 'Domingo',
            value: '0'
        },
        {
            name: 'Segunda-feira',
            value: '1'
        },
        {
            name: 'Terça-feira',
            value: '2'
        },
        {
            name: 'Quarta-feira',
            value: '3'
        },
        {
            name: 'Quinta-feira',
            value: '4'
        },
        {
            name: 'Sexta-feira',
            value: '5'
        },
        {
            name: 'Sábado',
            value: '6'
        }
    ]

    const hours = [{ name: 'De hora em hora', value: '*' }]
    for (let i = 0; i < 24; i++) {
        hours.push({ name: `${i}:00`, value: i.toString() })
    }

    const handleIntegrationChange = (integration_id) => {
        if (formData.integration_id !== integration_id && integration_id) {
            setFormData({ ...formData, integration_id: integration_id });
        }
    }

    const frequencyHourChange = (frequency_hour) => {
        if (formData.frequency_hour !== frequency_hour && frequency_hour) {
            setFormData({ ...formData, frequency_hour: frequency_hour });
        }
    }

    const frequencyDayWeekChange = (frequency_days_of_week) => {
        if (formData.frequency_days_of_week !== frequency_days_of_week && frequency_days_of_week) {
            setFormData({ ...formData, frequency_days_of_week: frequency_days_of_week });
        }
    }

    const handleProfileChange = (profile_title) => {
        if (formData.profile_title !== profile_title && profile_title) {
            setFormData({
                ...formData,
                profile_title: profile_title,
                creator_identifier: profiles.find((profile) => profile.title === profile_title).creator.identifier
            });
        }
    }

    const handleThread = (event) => {
        const { name, checked } = event.target;

        if (formData[name] !== checked) {
            setFormData({ ...formData, [name]: checked });
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (formData[name] !== value && value) {
            setFormData({ ...formData, [name]: value });
        }
    }

    const fetchIntegrations = useCallback(async () => {
        integrationIndexRequest(user.id, 1, 999).then((response) => {
            setIntegrations(response.data.data);
        })
    }, [user]);


    const fetchDomains = useCallback(async () => {
        accountByAccountRequest(user.id, 1, 999).then((response) => {
            setDomains(response.data.data);
        })
    }, [user]);

    const fetchProfiles = useCallback(async (domainName) => {
        profileShowDomainRequest(domainName, 1, 100).then((response) => {
            setProfiles(response.data.data);
        });
    }, []);


    const handleDomainChange = (value) => {
        if (formData.domain_name !== value) {
            setFormData({ ...formData, domain_name: value });
        }

        if (value) {
            fetchProfiles(value);
        } else {
            setProfiles([])
        }
    }

    useEffect(() => {
        if (Object.keys(integration).length > 0) {
            fetchProfiles(integration.domain_name);
            setFormData(integration);
        }
    }, [integration, fetchProfiles]);

    useEffect(() => {
        fetchIntegrations();
        fetchDomains();
    }, [fetchIntegrations, fetchDomains]);

    useEffect(() => {
        if (formData.integration_id && integrations.length > 0) {
            const integration = integrations.find((integration) => integration.id === Number(formData.integration_id));

            if (integration) {
                setHasChatIntegration(integration.type === 'whatsapp');
            }
        }

    }, [formData.integration_id, integrations]);

    const profilesInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="480px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='50' height='50' color='var(--accent-a12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Qual perfil escolher?
                        </Heading>
                        <Text as="div" size="2">
                            - Qual é o melhor perfil para desempenhar a ação?
                        </Text>
                        <Text as="div" size="2">
                            - Se nenhum perfil se encaixa, crie um novo(<LinkUI asChild><Link to='/perfis/novo'>clique aqui</Link></LinkUI>).
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const domainInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="480px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='var(--accent-12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Qual o domínio escolher?
                        </Heading>
                        <Text as="div" size="2">
                            - Qual é o melhor dominio para desempenhar a ação?
                        </Text>
                        <Text as="div" size="2">
                            - Se nenhum domínio se encaixa, crie um novo(<LinkUI asChild><Link to='/dominios/novo'>clique aqui</Link></LinkUI>).
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const textInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='var(--accent-12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Estimulos criativos
                        </Heading>
                        <Text as="div" size="2">
                            - O que você gostaria de saber?
                        </Text>

                        <Text as="div" size="2">
                            - O que você gostaria que a IA fizesse por você?
                        </Text>

                        <Text as="div" size="2">
                            - O que você gostaria de aprender?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const frequencyDaysInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='var(--accent-12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Frequência diária
                        </Heading>
                        <Text as="div" size="2">
                            - Qual é a frequência que você deseja que a ação seja executada?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const frequencyHoursInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='var(--accent-12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Frequência horária
                        </Heading>
                        <Text as="div" size="2">
                            - Qual é a frequência que você deseja que a ação seja executada?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const integrationInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='var(--accent-12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Qual integração escolher?
                        </Heading>
                        <Text as="div" size="2">
                            - Qual é a melhor integração para desempenhar a ação?
                        </Text>
                        <Text as="div" size="2">
                            - Se nenhuma integração se encaixa, crie uma nova(<LinkUI asChild><Link to='/integracoes/nova'>clique aqui</Link></LinkUI>).
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    return (
        <Box>
            {errors.length > 0 && (
                <Callout.Root mb='4' color="red">
                    <Callout.Icon>
                        <InfoCircledIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        {errors.map((error, index) => (
                            <Text key={index}>{error}</Text>
                        ))}
                    </Callout.Text>
                </Callout.Root>
            )}

            <Form.Root className="FormRoot" onSubmit={(e) => { handleSubmit(e, formData) }}>
                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Field className="FormField" name="integration_id">
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Integração</Form.Label>

                                <Form.Message className="FormMessage" match="typeMismatch">
                                    Você precisa selecionar uma integração
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild >
                                <Select.Root name='integration_id' onValueChange={handleIntegrationChange} value={formData?.integration_id} defaultValue={formData?.integration_id} required>
                                    <Select.Trigger className='Input' placeholder="Selecione uma integração abaixo">
                                        {formData?.integration_id ? integrations.find((integration) => integration.id === Number(formData.integration_id))?.name : 'Selecione uma integração abaixo'}
                                    </Select.Trigger>
                                    <Select.Content position="popper">
                                        <Select.Group>
                                            {integrations.map((integration) => (
                                                <Select.Item key={integration.id} value={integration.id}>{integration.name}</Select.Item>
                                            ))}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            </Form.Control>
                        </Form.Field>
                    </HoverCard.Trigger>
                    {integrationInstructions({left: '880px', top: '-45px'})}
                </HoverCard.Root>

                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Field className="FormField" name="frequency_hour">
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Frequência horária</Form.Label>

                                <Form.Message className="FormMessage" match="typeMismatch">
                                    Você precisa selecionar uma frequência
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <Select.Root name='frequency_hour' onValueChange={frequencyHourChange} value={formData?.frequency_hour} defaultValue={formData?.frequency_hour} required>
                                    <Select.Trigger className='Input' placeholder="Selecione a frequencia por hora">
                                        {formData.frequency_hour ? hours.find((hour) => hour.value === formData.frequency_hour).name : 'Selecione uma frequência abaixo'}
                                    </Select.Trigger>
                                    <Select.Content position="popper">
                                        <Select.Group>
                                            {hours.map((hour) => (
                                                <Select.Item key={hour.value} value={hour.value}>{hour.name}</Select.Item>
                                            ))}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            </Form.Control>
                        </Form.Field>
                    </HoverCard.Trigger>
                    {frequencyHoursInstructions({left: '880px', top: '-45px'})}
                </HoverCard.Root>

                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Field className="FormField" name="frequency_days_of_week">
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Frequência diária</Form.Label>

                                <Form.Message className="FormMessage" match="typeMismatch">
                                    Você precisa selecionar uma frequência
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <Select.Root name='frequency_days_of_week' onValueChange={frequencyDayWeekChange} value={formData?.frequency_days_of_week} required>
                                    <Select.Trigger className='Input' placeholder="Selecione a frequencia por dia">
                                        {formData.frequency_days_of_week ? daysOfWeek.find((day) => day.value === formData.frequency_days_of_week).name : 'Selecione uma frequência abaixo'}
                                    </Select.Trigger>
                                    <Select.Content position="popper">
                                        <Select.Group>
                                            {daysOfWeek.map((day) => (
                                                <Select.Item key={day.value} value={day.value}>{day.name}</Select.Item>
                                            ))}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            </Form.Control>
                        </Form.Field>
                    </HoverCard.Trigger>
                    {frequencyDaysInstructions({left: '880px', top: '-45px'})}
                </HoverCard.Root>

                <Separator my='4' size="4" />
                <Heading size="2" mb='4' style={{color: 'var(--accent-11)'}}>Configurações de integração</Heading>

                <Form.Field className="FormField" name="text">
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className="FormLabel">Ação</Form.Label>
                        <Form.Message className="FormMessage" match="valueMissing">
                            Descreva a ação
                        </Form.Message>
                    </Flex>

                    <HoverCard.Root>
                        <HoverCard.Trigger>
                            <Form.Control asChild>
                                <textarea className="Textarea" type='text' onChange={handleChange} value={formData?.text} required />
                            </Form.Control>
                        </HoverCard.Trigger>

                        {textInstructions({left: '880px', top: '-330px'})}
                    </HoverCard.Root>
                </Form.Field>

                {hasChatIntegration && <>
                    <Form.Field className="FormField" name="integration_event_identifier">
                        <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                            <Form.Label className="FormLabel">Chat ID</Form.Label>
                        </Flex>

                        <Form.Control asChild>
                            <input className="Input" type='text' name='integration_event_identifier' onChange={handleChange} value={formData?.integration_event_identifier} required />
                        </Form.Control>
                    </Form.Field>
                </>}

                <Form.Field className="FormFieldInline" name="thread">
                    <Form.Label className='FormLabel'>Thread</Form.Label>

                    <Form.Control asChild>
                        <input type='checkbox' className="Input" name='thread' onChange={handleThread} checked={formData?.thread} />
                    </Form.Control>
                </Form.Field>

                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Field className="FormField" name="domain_name">
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Domínio</Form.Label>

                                <Form.Message className="FormMessage" match="typeMismatch">
                                    Você precisa selecionar um domínio
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild >
                                <Select.Root name='domain_name' onValueChange={handleDomainChange} value={formData?.domain_name} defaultValue={formData?.domain_name} required>
                                    <Select.Trigger className='Input' placeholder="Selecione um dominio abaixo"/>
                                    <Select.Content position="popper">
                                        <Select.Group>
                                            {domains.map((attrs) => (
                                                <Select.Item key={attrs.domain.id} value={attrs.domain.name}>{attrs.domain.name}</Select.Item>
                                            ))}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            </Form.Control>
                        </Form.Field>
                    </HoverCard.Trigger>
                    {domainInstructions({left: '880px', top: '-45px'})}
                </HoverCard.Root>

                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Field className="FormField" name="profile_title">
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Perfil</Form.Label>

                                <Form.Message className="FormMessage" match="typeMismatch">
                                    Você precisa selecionar um perfil
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild >
                                <Select.Root name='profile_title' disabled={profiles.length === 0} onValueChange={handleProfileChange} value={formData?.profile_title} defaultValue={formData?.profile_title} required>
                                    <Select.Trigger className='Input' placeholder="Selecione um perfil"/>
                                    <Select.Content position="popper">
                                        <Select.Group>
                                            {profiles.map((profile) => (
                                                <Select.Item key={profile.id} value={profile.title}>{profile.title}</Select.Item>
                                            ))}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            </Form.Control>
                        </Form.Field>
                    </HoverCard.Trigger>
                    {profilesInstructions({left: '880px', top: '-45px'})}
                </HoverCard.Root>

                <Form.Submit asChild>
                    <Button size="2" type="submit" loading={loading}>
                        Salvar
                    </Button>
                </Form.Submit>
            </Form.Root>
        </Box>
    );
}

export { ScheduleIntegrationsForm }