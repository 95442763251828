import { useEffect, useState, useCallback } from 'react';
import { Card, Avatar, Box, Container, Heading, Text, Flex, Button, IconButton, Separator, Link as LinkUI, Popover } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { ChatBubbleIcon, Share1Icon } from '@radix-ui/react-icons';
import { useParams, useNavigate } from 'react-router-dom';
import { contentViewPublicShowRequest, contentViewPublicByDomainRequest } from '../../api/content_views';
import { MarkdownText } from '../../components/markdown_text';
import { SocialIcon } from 'react-social-icons';
import { INSTAGRAMDOMAINDEFAULT, TIKTOKDOMAINDEFAULT, YOUTUBEDOMAINDEFAULT } from '../../support/constants';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

function ContentViewShow() {
    const navigate = useNavigate();
    const { short_path } = useParams();
    const [contentViews, setContentViews] = useState({});
    const [domainName, setDomainName] = useState('');
    const [profileTitle, setProfileTitle] = useState('');
    const [creatorIdentifier, setCreatorIdentifier] = useState('');
    const [contentViewsRelated, setContentViewsRelated] = useState([]);

    const scrollOnTop = () => {
        window.scrollTo(0, 0);
    }

    const getContentViewsRelated = useCallback(() => {
        if (!domainName) return;

        contentViewPublicByDomainRequest(domainName, 1, 4).then((response) => {
            let attrs = []
            response.data.data.forEach((contentView) => {
                if (contentView.short_path !== short_path) {
                    attrs.push({
                        short_path: contentView.short_path,
                        text: contentView.text,
                        creator: contentView.responses[0].creator,
                        system: contentView.system
                    });
                }
            });

            setContentViewsRelated(attrs.slice(0, 3));
        }).catch((error) => {
            console.error(error);
        });
    }, [domainName, short_path]);

    useEffect(() => {
        contentViewPublicShowRequest(short_path).then((response) => {
            setContentViews(response.data.data);
            setDomainName(response.data.data[0].responses[0].domain?.name);
            setProfileTitle(response.data.data[0].responses[0].profile?.title);
            setCreatorIdentifier(response.data.data[0].responses[0].creator?.identifier);

            getContentViewsRelated();
        }).catch((error) => {
            console.error(error);
        });
    }, [short_path, getContentViewsRelated]);


    const goToPlayground = (e) => {
        e.preventDefault();
        navigate('/area-de-trabalho', { state: { domain_name: domainName, profile_title: profileTitle, creator_identifier: creatorIdentifier } });
    }

    const handleMediaUrl = (media) => {
        switch (media.media.name) {
            case 'Instagram':
                return INSTAGRAMDOMAINDEFAULT + media.identifier;
            case 'TikTok':
                return TIKTOKDOMAINDEFAULT + media.identifier;
            case 'YouTube':
                return YOUTUBEDOMAINDEFAULT + media.identifier;
            default:
                return '';
        }
    }

    const creatorsInfo = (creator) => {
        return (creator && <Box>
            <Heading style={{margin: '20px 0'}} size='4'>Quem é {creator.identifier}?</Heading>

            <Flex align='center' mt='2' style={{justifyContent: 'flex-start'}}>
                <Card>
                    <MarkdownText text={creator.description} />

                    {creator.creatorsMedias && <Flex mt='5' align='center' gap={creator.creatorsMedias.length}>
                        <Flex align='center' gap='2' style={{margin: '20px 0'}}>
                            <Heading size='4' className='Label'>Redes sociais:</Heading>
                            <Flex align='center' gap={creator.creatorsMedias.length}>
                                {creator.creatorsMedias.data.map((media, index) => (
                                    <SocialIcon url={handleMediaUrl(media)} style={{marginLeft: '5px', width: '35px', height: '35px'}} target='_blank' />
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>}
                </Card>
            </Flex>

            <Flex align='center' mt='4' style={{justifyContent: 'flex-start'}}>
                <Text className='Label'>
                    Fale com {creator.identifier}
                </Text>

                <Button className='IconButtonPopOver' ml='6' onClick={goToPlayground} style={{cursor: 'pointer', marginLeft: '10px'}}>
                    <ChatBubbleIcon />
                </Button>
            </Flex>
        </Box>)
    }

    const systemAvatar = () => {
        return (
            <Flex direction='column' align='center'>
                <IconButton size='4' radius='full' style={{width: '80px', height: '80px'}}>
                    <Share1Icon width='30' height='30' />
                </IconButton>
            </Flex>
        );
    }

    const creatorsAvatar = (creator) => {
        return (creator ? (<Flex direction='column' align='center'>
            <Popover.Root>
                <Popover.Trigger>
                    <Avatar
                        size={{
                            initial: '3',
                            lg: '6',
                        }}
                        src={creator.image}
                        radius="full"
                        fallback={creator.identifier[0]}
                    />
                </Popover.Trigger>
                <Popover.Content width={{
                    initial: '300px',
                    xs: '300px',
                    sm: '300px',
                    md: '300px',
                    lg: '620px',
                }}>
                    {creatorsInfo(creator)}
                </Popover.Content>
            </Popover.Root>
        </Flex>) : systemAvatar());
    }

    const contentOfContentViewsRelated = () => {
        return contentViewsRelated.map((contentView, index) => (
            <LinkUI asChild>
                <Link to={`/pergunta/${contentView.short_path}`} onClick={scrollOnTop} style={{width: '100%'}}>
                    <Card key={index} variant='ghost' style={{
                        backgroundColor: 'var(--gray-a3)',
                        borderColor: 'var(--gray-8)',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        color: 'var(--gray-11)',
                        padding: 'var(--space-4)',
                        margin: '0 0 var(--space-2)'
                    }}>
                        <Flex direction='column' justify='center' maxWidth='265px' maxHeight='100%' height='100%'>
                            <Heading size='3' mb='3'>Alguém perguntou:</Heading>
                            <Text truncate wrap='pretty'>
                                {contentView.text}
                            </Text>
                        </Flex>
                    </Card>
                </Link>
            </LinkUI>
        ))
    }

    return (
        <Container size='4' p='2' style={{minHeight: '100%', backgroundColor: 'var(--gold-a4)'}}>
            {contentViews.length > 0 && contentViews.map((contentView, index) => (
                <Flex direction='column'>
                    {!contentView.system &&
                        <Card key={index} variant='ghost' style={{
                            backgroundColor: 'var(--lime-a3)',
                            borderColor: 'var(--lime-8)',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            padding: 'var(--space-4)',
                            margin: 'var(--space-4) 0 0'
                        }}>
                            <Heading size='3' mb='3'>Alguém perguntou:</Heading>
                            <MarkdownText key={index} text={contentView.text} />
                        </Card>}


                    {contentView.responses && contentView.responses.map((response, index) => (
                        <Flex key={index} gap="3" align="center" mt='4' style={{alignItems: 'flex-end'}}>
                            <Box display={displayOnlyLaptopsAndDesktops()}>
                                {creatorsAvatar(response.creator)}
                            </Box>

                            <Card key={index} maxWidth='100%' style={{padding: 'var(--space-4)', width: '100%'}}>
                                <Box display={displayOnlyLaptopsAndDesktops()}>
                                    {!contentView.system && <Heading size='3' mb='3'>{response.creator.identifier} respondeu:</Heading>}
                                </Box>

                                {!contentView.system && <Flex align='center' gap='2' display={displayOnlyMobiles(true)} style={{alignItems: 'flex-end'}}>
                                    {creatorsAvatar(response.creator)}
                                    <Heading size='3' mb='3'>{response.creator.identifier} respondeu:</Heading>
                                </Flex>}
                                <MarkdownText key={response.id} text={response.text} system={contentView.system} />
                            </Card>
                        </Flex>
                    ))}
                </Flex>

            ))}

            {contentViewsRelated && <Flex direction='column' mt='4'>
                <Separator size='4' my='4' />
                <Heading className='Label' size='4'>O que mais estão perguntando?</Heading>
                <Heading className='Label' size='8' my='4'>Veja:</Heading>

                <Flex align='center' gap='3' display={displayOnlyLaptopsAndDesktops(true)} style={{justifyContent: 'flex-start'}}>
                    {contentOfContentViewsRelated()}
                </Flex>

                <Flex align='center' gap='3' display={displayOnlyMobiles(true)} style={{justifyContent: 'center'}} direction='column'>
                    {contentOfContentViewsRelated()}
                </Flex>
            </Flex>}
        </Container>
    );
}

export { ContentViewShow };