import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Button, Flex, Text, IconButton, Heading, Box, Select, HoverCard, Callout, Link as LinkUI, Separator } from "@radix-ui/themes";
import * as Form from "@radix-ui/react-form";
import { ArrowRightIcon, ArrowLeftIcon, TrashIcon, InfoCircledIcon, Pencil1Icon } from "@radix-ui/react-icons";
import { useAuth } from '../../../hooks/auth';
import { creatorInputsNewRequest, creatorInputsDomainNewRequest } from '../../../api/creator_inputs';
import { accountByAccountRequest } from '../../../api/accounts_domains';
import { linkExtractionCreateRequest } from '../../../api/link_extractions';

function CategorizeForm(props) {
    const { user } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [links, setLinks] = useState([]);
    const [expiredAt, setExpiredAt] = useState();
    const [text, setText] = useState();
    const [loading, setLoading] = useState(false);
    const [state] = useState(location.state ? location.state : {});
    const [domains, setDomains] = useState([]);
    const [serverErrors, setServerErrors] = useState({});
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        creator_identifier: user.name
    });

    const getAllDomains = useCallback((page) => {
        accountByAccountRequest(user.id, page, 999).then((response) => {
            const domainNames = response.data.data.map(({ domain, id }) => ({ 'id': id, 'name': domain.name }));
            setDomains(domainNames);
        }).catch((error) => {
            console.log(error);
        });
    }, [user]);

    const handleDomainChange = (value) => {
        setServerErrors({ domain_name: false });
        setFormData({
            ...formData,
            domain_name: value
        });
    }

    var handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            iat: new Date().toISOString(),
            files: files,
            links: links,
            text: text,
        });
    };

    var handleDomainValidation = function(a) {
        if (!formData.domain_name) {
            setServerErrors({ domain_name: true });
        } else {
            setServerErrors({ domain_name: false });
        }
    }

    const handleExpiredAt = (e) => {
        setExpiredAt(e.target.value);
    }


    var handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);


        if (formData.text) {
            var body = {
                creator_identifier: formData.creator_identifier,
                title: formData.title,
                content: formData.text,
                expired_at: expiredAt,
            }

            creatorInputsNewRequest(body).then((_) => {
                var creatorInputsDomainBody = {
                    "domain_name": formData.domain_name,
                    "creator_input_title": formData.title
                }

                creatorInputsDomainNewRequest(creatorInputsDomainBody).then(() => {
                    setLoading(false);
                    navigate('/memorias/nova/sucesso', { state: { tabs: 'creator_inputs' } });
                }).catch((error) => {
                    setLoading(false);
                    setErrors([error.response.data.error]);
                });
            }).catch((error) => {
                setLoading(false);
                setErrors([error.response.data.error]);
            });
        } else if (formData.files.length > 0) {
            const formDataFile = new FormData();
            formData.files.forEach((file) => {
                formDataFile.append('files', file);
            });

            formDataFile.append('dataAttributes', JSON.stringify({
                creator_identifier: formData.creator_identifier,
                title: formData.title,
                domain_name: formData.domain_name,
            }));

            formDataFile.append('accountId', user.id);

            linkExtractionCreateRequest(formDataFile).then((_) => {
                setLoading(false);
                navigate('/memorias/nova/sucesso', { state: { tabs: 'link_extractions' } });
            }).catch((error) => {
                setLoading(false);
                setErrors([error.response.data.error]);
            })
        } else if (formData.links.length > 0) {
            const formDataFile = new FormData();

            formDataFile.append('dataAttributes', JSON.stringify({
                creator_identifier: formData.creator_identifier,
                title: formData.title,
                domain_name: formData.domain_name,
            }));

            formDataFile.append('links', formData.links);
            formDataFile.append('accountId', user.id);

            linkExtractionCreateRequest(formDataFile).then((_) => {
                setLoading(false);
                navigate('/memorias/nova/sucesso', { state: { tabs: 'link_extractions' } });
            }).catch((error) => {
                setLoading(false);
                setErrors([error.response.data.error]);
            })
        }
    }

    useEffect(() => {
        setFiles(state.files);
        setLinks(state.links);
        setText(state.text);
        getAllDomains(1);
    }, [state, getAllDomains]);

    const removeFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    }

    const removeLink = (index) => {
        setLinks(links.filter((_, i) => i !== index));
    }

    const filesHtml = files.length > 0 ? (
        <Box pt='4' pb='4'>
            <Heading size="2">Arquivos a enviar</Heading>
            {files.map((file, index) => (
                <Flex key={index} align="center" p='1' style={{ justifyContent: 'space-between' }}>
                    <Text>{file.name}</Text>
                    <IconButton size="1" color="red" onClick={() => removeFile(index)}>
                        <TrashIcon width={20} height={20} />
                    </IconButton>
                </Flex>
            ))}
        </Box>
    ) : null;

    const linksHtml = links.length > 0 ? (
        <Box pt='4' pb='4'>
            <Heading size="2">Links a enviar</Heading>
            {links.map((link, index) => (
                <Flex key={index} align="center" p='1' style={{ justifyContent: 'space-between' }}>
                    <Text>{link}</Text>
                    <IconButton size="1" color="red" onClick={() => removeLink(index)}>
                        <TrashIcon width={20} height={20} />
                    </IconButton>
                </Flex>
            ))}
        </Box>
    ) : null;

    const backStep = (e) => {
        e.preventDefault();
        navigate('/memorias/nova', { state: state });
    }

    const domainInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="300px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='50' height='50' color='var(--accent-a12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Selecione corretamente
                        </Heading>
                        <Text as="div" size="2">
                            Sua memória será inserida no domínio que você escolher.
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const truncatedText = text ? text.substring(0, 100) + (text.length > 100 ? '...' : '') : '';
    const textHtml = truncatedText ? (
        <Flex direction='column' align='baseline' pt='4' pb='4' maxWidth='100%'>
            <Heading size="3" mb='2'>Mémoria a ser inserida</Heading>
            <Callout.Root color='blue'>
                <Callout.Icon>
                    <InfoCircledIcon />
                </Callout.Icon>
                <Callout.Text>
                    <Flex gap='2' align='center'>
                        {truncatedText}
                        <LinkUI ml='2' asChild>
                            <Link onClick={backStep} ml='2'>
                                <Flex gap='2' align='center'>
                                    <Pencil1Icon />
                                    Editar
                                </Flex>
                            </Link>
                        </LinkUI>
                    </Flex>
                </Callout.Text>
            </Callout.Root>
        </Flex>
    ) : null;

    return (
        <>
            {errors.length > 0 && (
                <>
                    <Callout.Root color='red'>
                        <Callout.Icon>
                            <InfoCircledIcon />
                        </Callout.Icon>
                        <Callout.Text>
                            <Flex gap='2' align='center'>
                                {errors.map((error) => (
                                    <Text key={error}>{error}</Text>
                                ))}
                            </Flex>
                        </Callout.Text>
                    </Callout.Root>
                    <Separator size="4" my='4' />
                </>
            )}

            {filesHtml}
            {linksHtml}
            {textHtml}

            <Form.Root className="FormRoot" onSubmit={handleSubmit}>
                <Form.Field className="FormField" name="title">
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className="FormLabel" style={{ paddingBottom: 'var(--space-2)', display: 'flex', flexDirection: 'line', alignItems: 'center', justifyContent: 'space-around' }}>
                            <Text>Insira o título</Text>
                        </Form.Label>

                        <Form.Message className="FormMessage" match="valueMissing">
                            Insira o título
                        </Form.Message>
                    </Flex>

                    <Form.Control asChild>
                        <input className="Input" name="title" onChange={handleChange} required />
                    </Form.Control>
                </Form.Field>

                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Field className="FormField" name="domain_name" serverInvalid={serverErrors.domain_name}>
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className="FormLabel" style={{ paddingBottom: 'var(--space-2)', display: 'flex', flexDirection: 'line', alignItems: 'center', justifyContent: 'space-around' }}>
                                    <Text>Selecione um domínio</Text>
                                </Form.Label>

                                <Form.Message className="FormMessage" match="typeMismatch" forceMatch={serverErrors.domain_name}>
                                    Você precisa selecionar um domínio
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <Select.Root onValueChange={handleDomainChange} value={formData.domain_name} required>
                                    <Select.Trigger className='Input' placeholder="Selecione uma categoria abaixo"/>
                                    <Select.Content position="popper">
                                        <Select.Group>
                                            {domains.map((domain) => (
                                                <Select.Item key={domain.id} value={domain.name}>{domain.name}</Select.Item>
                                            ))}
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            </Form.Control>
                        </Form.Field>
                    </HoverCard.Trigger>
                    {domainInstructions({left: '850px', top: '-40px'})}
                </HoverCard.Root>

                <Form.Field className="FormField" name="title">
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className="FormLabel" style={{ paddingBottom: 'var(--space-2)', display: 'flex', flexDirection: 'line', alignItems: 'center', justifyContent: 'space-around' }}>
                            <Text>Expira em</Text>
                        </Form.Label>
                    </Flex>

                    <Form.Control asChild>
                        <input className="Input" onChange={handleExpiredAt} value={expiredAt} type='date' format='yyyy-MM-dd' min={new Date().toISOString().split('T')[0]} />
                    </Form.Control>
                </Form.Field>

                <Button size="2" color="gray" onClick={backStep}>
                    <ArrowLeftIcon width={20} height={20} />
                    Voltar
                </Button>

                <Form.Submit className='FormSubmit' asChild>
                    <Button size="2" ml='4' loading={loading} onClick={handleDomainValidation}>
                        Enviar
                        <ArrowRightIcon width={20} height={20}/>
                    </Button>
                </Form.Submit>
            </Form.Root>
        </>
    )
}

export { CategorizeForm };