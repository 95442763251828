import React, { useEffect, useCallback, useState } from 'react';
import { Heading, Flex, Strong, Text } from '@radix-ui/themes';
import { contentViewResponseShowRequest } from '../../../api/content_view_responses';
import { MarkdownText } from '../../../components/markdown_text';
import { DotFilledIcon } from '@radix-ui/react-icons';

function WhatsappShow(props) {
    const { dataIntegration } = props;
    const [contentViewResponse, setContentViewResponse] = useState();

    const fetchContentViewResponse = useCallback(async () => {
        const contentViewResponseId = JSON.parse(dataIntegration.data).content_view_response_id;
        contentViewResponseShowRequest(contentViewResponseId).then((response) => {
            setContentViewResponse(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }, [dataIntegration.data]);

    useEffect(() => {
        fetchContentViewResponse();
    }, [fetchContentViewResponse]);

    return (
        <>
            <Flex direction='column' gap='4'>
                <Flex direction='column' gap='1'>
                    <Strong>ID</Strong>
                    <Text>{dataIntegration.id}</Text>
                </Flex>

                <Flex direction='column' gap='1'>
                    <Strong>URL</Strong>
                    <Text>{JSON.parse(dataIntegration.data).url}</Text>
                </Flex>

                <Flex direction='column' gap='1'>
                    <Strong>Método</Strong>
                    <Text>{JSON.parse(dataIntegration.data).method}</Text>
                </Flex>
            </Flex>

            <Heading mt='4'>Configuração da ação</Heading>

            <Flex direction='column' gap='4'>
                <Flex direction='column' gap='1'>
                    <Strong>Ação</Strong>
                    <Text>{JSON.parse(dataIntegration.data).question}</Text>
                </Flex>

                <Flex direction='column' gap='1'>
                    <Strong>Domínio</Strong>
                    <Text>{JSON.parse(dataIntegration.schedule_integration.configurations).domain_name}</Text>
                </Flex>

                <Flex direction='column' gap='1'>
                    <Strong>Perfil</Strong>
                    <Text>{JSON.parse(dataIntegration.schedule_integration.configurations).profile_title}</Text>
                </Flex>
            </Flex>

            <Heading mt='4'>
                Resposta do serviço
                <DotFilledIcon color={JSON.parse(dataIntegration.data).status_code === 200 ? 'green' : 'red'} />
            </Heading>

            <Flex direction='column' gap='4'>
                <Flex direction='column' gap='1'>
                    <Strong>Status</Strong>
                    <Text>{JSON.parse(dataIntegration.data).status_code}</Text>
                </Flex>

                <Flex direction='column' gap='1'>
                    <Strong>Response Body</Strong>
                    <MarkdownText text={'```python' + JSON.parse(dataIntegration.data).body + '```'} />
                </Flex>
            </Flex>

            <Heading mt='4'>Conteúdo gerado</Heading>

            <Flex direction='column'>
                <MarkdownText text={contentViewResponse?.text} />
            </Flex>
        </>
    );
}

export { WhatsappShow }