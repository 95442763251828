import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Heading } from '@radix-ui/themes';
import { MemoriesNew } from '../memories/new';
import { creatorInputsShowRequest } from '../../api/creator_inputs';
import { Loading } from '../../components/loading';

function CreatorInputsEdit() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [creatorInput, setCreatorInput] = useState({})
    const [content, setContent] = useState('');
    const [expiredAt, setExpiredAt] = useState();

    useEffect(() => {
        const fetchCreatorInputs = (id) => {
            setLoading(true);
            creatorInputsShowRequest(id).then((response) => {
                setCreatorInput(response.data);

                const latestMemory = response.data.memories[0];
                setContent(latestMemory.content);
                if (latestMemory.expired_at)
                    setExpiredAt(new Date(latestMemory.expired_at).toISOString().split('T')[0]);
                setLoading(false);
            })
        }

        fetchCreatorInputs(id);
    }, [id]);

    return (
        <Container size='3' p='2'>
            {loading && <Loading />}
            {!loading && (<>
                <Heading>Editar memória</Heading>
                <MemoriesNew text={content} expiredAt={expiredAt} creatorInput={creatorInput} />
            </>)}
        </Container>
    );
}

export { CreatorInputsEdit }