import { Box, Button, Flex, Text } from '@radix-ui/themes';
import { EnterIcon, TextIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function SuccessStep(props) {
    const { user, updateSession, completeStep } = props;

    const navigate = useNavigate();

    const goTo = async (e, path) => {
        e.preventDefault();

        user.onboarding = true;
        updateSession(user)
        completeStep(3)

        navigate(path)
    }

    useEffect(() => {
    }, [completeStep]);

    return (
        <Flex direction='column' align='center'>
            <Box>
                <Flex direction='column' gap='2'>
                    <Text>Sua inteligência artificial foi criada!</Text>
                    <Text>Você já pode começar a usá-la.</Text>
                </Flex>
            </Box>

            <Flex justify='base' mt='4'>
                <Button onClick={(e) => goTo(e, '/area-de-trabalho')}>
                    <EnterIcon />
                    Começar
                </Button>

                <Button ml='2' onClick={(e) => goTo(e, '/memorias/nova')}>
                    <TextIcon />
                    Inserir memórias
                </Button>
            </Flex>
        </Flex>
    );
}

export { SuccessStep }