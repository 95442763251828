import {
    Text,
    Box,
    Flex,
    TabNav,
    Button,
    DropdownMenu,
    Avatar,
    Switch
} from '@radix-ui/themes';
import { PersonIcon, ArrowDownIcon, ChatBubbleIcon, HamburgerMenuIcon, Link1Icon, MoonIcon, RocketIcon, TokensIcon, ExitIcon, GlobeIcon, IdCardIcon, LightningBoltIcon } from '@radix-ui/react-icons'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { Logo } from './logo';
import { displayOnlyLaptopsAndDesktops } from '../contexts/responsive_tools';

function PrivateHeader() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, accountConfiguration, updateAccountConfiguration } = useAuth();

    const themeChange = (e) => {
        e.preventDefault();
        const appearance = handleAppearanceValue() ? 'light' : 'dark';
        updateAccountConfiguration({ ...accountConfiguration, appearance });
        navigate(0, { replace: true })
    }

    const handleAppearanceValue = () => {
        return accountConfiguration.appearance === 'dark' ? true : false;
    }

    const goTo = (url) => {
        navigate(url)
    }

    const UseIsActive = (path) => {
        var pathname = location.pathname;
        return (path !== '/area-de-trabalho' && pathname.startsWith(path)) || pathname === path;
    }

    const headerContent = () => {
        return (
            <>
                <Flex gap="3" p='2' align='center' style={{ justifyContent: 'space-between' }}>
                    <Box>
                        <Logo />
                    </Box>

                    <Box display={displayOnlyLaptopsAndDesktops()}>
                        <TabNav.Root>
                            <TabNav.Link active={UseIsActive('/area-de-trabalho')} onClick={ () => { goTo('/area-de-trabalho') }}>
                                <Flex gap='2' align='center' >
                                    <ChatBubbleIcon />
                                    Área de trabalho
                                </Flex>
                            </TabNav.Link>
                            <TabNav.Link active={UseIsActive('/memorias')} onClick={ () => { goTo('/memorias') }}>
                                <Flex gap='2' align='center' >
                                    <TokensIcon />
                                    Mémorias
                                </Flex>
                            </TabNav.Link>
                            <TabNav.Link active={UseIsActive('/perfis')} onClick={ () => { goTo('/perfis') }}>
                                <Flex gap='2' align='center' >
                                    <IdCardIcon />
                                    Perfis
                                </Flex>
                            </TabNav.Link>
                            <TabNav.Link active={UseIsActive('/eventos-recebidos') || UseIsActive('/moderacoes')} onClick={ () => { goTo('/eventos-recebidos') }}>
                                <Flex gap='2' align='center' >
                                    <LightningBoltIcon />
                                    Eventos Recebidos
                                </Flex>
                            </TabNav.Link>
                        </TabNav.Root>
                    </Box>

                    <Box>
                        <Flex gap="2" align='center'>
                            <Box display={displayOnlyLaptopsAndDesktops()}>
                                <DropdownMenu.Root>
                                    <DropdownMenu.Trigger>
                                        <Button size='3'>
                                            Começar
                                            <ArrowDownIcon width={20} height={20} />
                                        </Button>
                                    </DropdownMenu.Trigger>
                                    <DropdownMenu.Content>
                                        <DropdownMenu.Item style={{ height: 'auto', cursor: 'pointer' }} onClick={ () => { goTo('/memorias/nova') }}>
                                            <Flex gap="2" align="center" p='2' style={{ justifyContent: 'space-between' }}>
                                                <TokensIcon height={30} width={30} />
                                                <Box>
                                                    <Text as="div" size="2" weight="bold">
                                                        Nova memória
                                                    </Text>
                                                    <Text as="div" size="2">
                                                        Envie suas mémorias para a plataforma
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </DropdownMenu.Item>
                                        <DropdownMenu.Separator />
                                        <DropdownMenu.Item style={{ height: 'auto', cursor: 'pointer' }} onClick={ () => { goTo('/integracoes/nova') }}>
                                            <Flex gap="2" align="center" p='2' style={{ justifyContent: 'space-between' }}>
                                                <Link1Icon height={30} width={30} />
                                                <Box>
                                                    <Text as="div" size="2" weight="bold">
                                                        Criar uma integraçao
                                                    </Text>
                                                    <Text as="div" size="2">
                                                        Link seu whatsapp, telegram, etc
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </DropdownMenu.Item>
                                    </DropdownMenu.Content>
                                </DropdownMenu.Root>
                            </Box>

                            <Flex gap="2" align='center'>
                                <Box>
                                    <DropdownMenu.Root>
                                        <DropdownMenu.Trigger>
                                            <Flex gap='2' align='center'>
                                                <Avatar fallback={user.name[0]} src={user.image}/>
                                                <HamburgerMenuIcon width='25' height='25' />
                                            </Flex>
                                        </DropdownMenu.Trigger>

                                        <DropdownMenu.Content>
                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/area-de-trabalho') }}>
                                                <Flex gap='2' align='center' >
                                                    <ChatBubbleIcon />
                                                    Área de trabalho
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/memorias') }}>
                                                <Flex gap='2' align='center' >
                                                    <TokensIcon />
                                                    Minhas Memórias
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/acoes') }}>
                                                <Flex gap='2' align='center' >
                                                    <RocketIcon />
                                                    Minhas Ações
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/integracoes') }}>
                                                <Flex gap='2' align='center' >
                                                    <Link1Icon />
                                                    Integrações
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/eventos-recebidos') }}>
                                                <Flex gap='2' align='center' >
                                                    <LightningBoltIcon />
                                                    Eventos Recebidos
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Separator />

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/dominios') }}>
                                                <Flex gap='2' align='center' >
                                                    <GlobeIcon />
                                                    Meus Domínios
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/perfis') }}>
                                                <Flex gap='2' align='center' >
                                                    <IdCardIcon />
                                                    Meus Perfis
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Separator />

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/configuracoes') }}>
                                                <Flex gap='2' align='center' >
                                                    <PersonIcon />
                                                    Configurações
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Separator />

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={themeChange}>
                                                <Flex gap="2" align='center'>
                                                    <Switch defaultChecked={handleAppearanceValue} size="1" />
                                                    <MoonIcon />
                                                    <Text>Tema</Text>
                                                </Flex>
                                            </DropdownMenu.Item>

                                            <DropdownMenu.Item style={{cursor: 'pointer'}} onClick={ () => { goTo('/sair') }}>
                                                <Flex gap='2' align='center' >
                                                    <ExitIcon />
                                                    Sair
                                                </Flex>
                                            </DropdownMenu.Item>
                                        </DropdownMenu.Content>
                                    </DropdownMenu.Root>
                                </Box>
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
            </>
        )
    }

    const onlyLogo = () => {
        return (
            <Flex gap="3" p='2' style={{ justifyContent: 'space-between' }}>
                <Box>
                    <Logo />
                </Box>
                <Box></Box>
                <Box>
                    <Button onClick={ () => { goTo('/sair') }}>
                        <ExitIcon />
                    </Button>
                </Box>
            </Flex>
        )
    }

    return (
        <Box>
            {user.onboarding && headerContent()}
            {!user.onboarding && onlyLogo()}
        </Box>
    );
}

export { PrivateHeader };