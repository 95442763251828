// Hooks
import { useAuth } from './hooks/auth';
import { PrivateRoute } from './contexts/private_routes';
import { PublicRoute } from './contexts/public_routes';

// Components
import { PrivateHeader } from './components/private_header';
import { PublicHeader } from './components/public_header';

// Public page
import { RegisterNew } from './pages/register/new';
import { RegisterSucess } from './pages/register/sucess';
import { SessionNew } from './pages/session/new';
import { ForgotPasswordNew } from './pages/forgot_password/new';
import { ForgotPasswordSuccess } from './pages/forgot_password/success';
import { ResetPasswordNew } from './pages/reset_password/new';

// Private page
import { Logout } from './pages/logout';

import { Home } from './pages/home';
import { Desktop } from './pages/desktop';
import { WelcomeNew } from './pages/welcome/new';

import { CreatorInputsIndex } from './pages/creator_inputs/index';
import { CreatorInputsNew } from './pages/creator_inputs/new';
import { CreatorInputsCategorize } from './pages/creator_inputs/categorize';
import { CreatorInputsSucess } from './pages/creator_inputs/sucess';

import { ScheduleIntegrationIndex } from './pages/schedule_integrations/index';
import { ScheduleIntegrationNew } from './pages/schedule_integrations/new';
import { ScheduleIntegrationEdit } from './pages/schedule_integrations/edit';
import { ScheduleIntegrationsShow } from './pages/schedule_integrations/show';

import { DataIntegrationsShow } from './pages/data_integrations/show';

import { DomainsIndex } from './pages/domains/index';
import { DomainsNew } from './pages/domains/new';
import { DomainsSucess } from './pages/domains/sucess';
import { DomainsEdit } from './pages/domains/edit';

import { CreatorInputsShow } from './pages/creator_inputs/show';
import { CreatorInputsEdit } from './pages/creator_inputs/edit';

import { ContentViewShow } from './pages/content_views/show';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Configurations } from './pages/configurations';
import { ConfigurationsEdit } from './pages/configurations/edit';

import { ProfileIndex } from './pages/profiles/index';
import { ProfileNew } from './pages/profiles/new';
import { ProfileShow } from './pages/profiles/show';
import { ProfileEdit } from './pages/profiles/edit';

import { IntegrationIndex } from './pages/integrations/index';
import { IntegrationNew } from './pages/integrations/new';
import { IntegrationEdit } from './pages/integrations/edit';
import { IntegrationShow } from './pages/integrations/show';

import { EventsReceivedIndex } from './pages/events/received/index';
import { EventsReceivedShow } from './pages/events/received/show';

import { EventsModerationShow } from './pages/events/moderation/show';

import { EmptyList } from './components/empty_list';

import { Box } from '@radix-ui/themes';

function App() {
  return (
    <Router>
      { useAuth()?.user ? <PrivateHeader /> : <PublicHeader />  }

      <Box>
        <Routes>
          <Route path="/" element={
            <PublicRoute>
              <Home />
            </PublicRoute>
          } />

          <Route path="/cadastro" element={
            <PublicRoute>
              <RegisterNew />
            </PublicRoute>
          } />

          <Route path="/cadastro/sucesso" element={
            <PublicRoute>
              <RegisterSucess />
            </PublicRoute>
          } />

          <Route path="/login" element={
            <PublicRoute>
              <SessionNew />
            </PublicRoute>
          } />

          <Route path="/recuperar_senha" element={
            <PublicRoute>
              <ForgotPasswordNew />
            </PublicRoute>
          } />

          <Route path="/recuperar_senha/sucesso" element={
            <PublicRoute>
              <ForgotPasswordSuccess />
            </PublicRoute>
          } />

          <Route path="/trocar_senha" element={
            <PublicRoute>
              <ResetPasswordNew />
            </PublicRoute>
          } />

          <Route path="/pergunta/:short_path" element={
            <PublicRoute>
              <ContentViewShow />
            </PublicRoute>
          } />

          <Route path="/sair" element={
            <PrivateRoute>
              <Logout />
            </PrivateRoute>
          } />

          <Route path="/welcome" element={
            <PrivateRoute>
              <WelcomeNew />
            </PrivateRoute>
          } />

          <Route path="/area-de-trabalho" element={
            <PrivateRoute>
              <Desktop />
            </PrivateRoute>
          } />

          <Route path="/acoes" element={
            <PrivateRoute>
              <ScheduleIntegrationIndex />
            </PrivateRoute>
          } />

          <Route path="/acoes/nova" element={
            <PrivateRoute>
              <ScheduleIntegrationNew />
            </PrivateRoute>
          } />

          <Route path="/acoes/:id" element={
            <PrivateRoute>
              <ScheduleIntegrationsShow />
            </PrivateRoute>
          } />

          <Route path="/acoes/:id/editar" element={
            <PrivateRoute>
              <ScheduleIntegrationEdit />
            </PrivateRoute>
          } />

          <Route path="/memorias" element={
            <PrivateRoute>
              <CreatorInputsIndex />
            </PrivateRoute>
          } />

          <Route path="/memorias/nova" element={
            <PrivateRoute>
              <CreatorInputsNew />
            </PrivateRoute>
          } />

          <Route path="/memorias/nova/sucesso" element={
            <PrivateRoute>
              <CreatorInputsSucess />
            </PrivateRoute>
          } />

          <Route path="/memorias/nova/categorizar" element={
            <PrivateRoute>
              <CreatorInputsCategorize />
            </PrivateRoute>
          } />


          <Route path="/memorias/:id" element={
            <PrivateRoute>
              <CreatorInputsShow />
            </PrivateRoute>
          } />

          <Route path="/memorias/:id/editar" element={
            <PrivateRoute>
              <CreatorInputsEdit />
            </PrivateRoute>
          } />

          <Route path="/dominios" element={
            <PrivateRoute>
              <DomainsIndex />
            </PrivateRoute>
          } />

          <Route path="/dominios/novo" element={
            <PrivateRoute>
              <DomainsNew />
            </PrivateRoute>
          } />

          <Route path="/dominios/sucesso" element={
            <PrivateRoute>
              <DomainsSucess />
            </PrivateRoute>
          } />

          <Route path="/dominios/:id/editar" element={
            <PrivateRoute>
              <DomainsEdit />
            </PrivateRoute>
          } />

          <Route path='/perfis' element={
            <PrivateRoute>
              <ProfileIndex />
            </PrivateRoute>
          } />

          <Route path='/perfis/novo' element={
            <PrivateRoute>
              <ProfileNew />
            </PrivateRoute>
          } />

          <Route path='/perfis/:id' element={
            <PrivateRoute>
              <ProfileShow />
            </PrivateRoute>
          } />

          <Route path="/perfis/:id/editar" element={
            <PrivateRoute>
              <ProfileEdit />
            </PrivateRoute>
          } />

          <Route path="/configuracoes" element={
            <PrivateRoute>
              <Configurations />
            </PrivateRoute>
          } />

          <Route path="/configuracoes/editar" element={
            <PrivateRoute>
              <ConfigurationsEdit />
            </PrivateRoute>
          } />

          <Route path="/integracoes" element={
            <PrivateRoute>
              <IntegrationIndex />
            </PrivateRoute>
          } />

          <Route path="/integracoes/nova" element={
            <PrivateRoute>
              <IntegrationNew />
            </PrivateRoute>
          } />

          <Route path="/integracoes/:id/editar" element={
            <PrivateRoute>
              <IntegrationEdit />
            </PrivateRoute>
          } />

          <Route path="/integracoes/:id" element={
            <PrivateRoute>
              <IntegrationShow />
            </PrivateRoute>
          } />

          <Route path="/moderacoes/:id" element={
            <PrivateRoute>
              <EventsModerationShow />
            </PrivateRoute>
          } />

          <Route path="/eventos-recebidos" element={
            <PrivateRoute>
              <EventsReceivedIndex />
            </PrivateRoute>
          } />

          <Route path="/eventos-recebidos/:chatId" element={
            <PrivateRoute>
              <EventsReceivedShow />
            </PrivateRoute>
          } />

          <Route path="/detalhe-integracao/:id" element={
            <PrivateRoute>
              <DataIntegrationsShow />
            </PrivateRoute>
          } />

          <Route path="*" element={
            <PrivateRoute>
              <EmptyList />
            </PrivateRoute>
          } />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
