import { Popover, TextField, AlertDialog, IconButton, SegmentedControl, TextArea, Button, Flex, Spinner, Callout, Badge, Select, Text, Separator, Box, Container, ScrollArea, HoverCard, DataList, Strong } from '@radix-ui/themes'
import { useState, useEffect, useRef, useCallback } from 'react';
import { Pencil1Icon, LockClosedIcon, InfoCircledIcon, ArrowUpIcon, ChatBubbleIcon, Share1Icon, TrashIcon, CopyIcon, CheckCircledIcon, MixerVerticalIcon, DoubleArrowDownIcon } from '@radix-ui/react-icons';
import * as Toast from '@radix-ui/react-toast';
import { useAuth } from '../hooks/auth';
import { accountsDomainAllRequest } from '../api/accounts_domains';
import { profileShowDomainRequest } from '../api/profiles';
import { contentViewUpdateRequest, contentViewMarkAsPublicRequest, contentViewMarkAsPrivateRequest, contentViewArchiveRequest, contentViewsCreateRequest, contentViewThreadRequest, contentViewThreadTitlesRequest, contentViewsShowRequest } from '../api/content_views';
import { useNavigate, useLocation } from 'react-router-dom';
import { MarkdownText } from '../components/markdown_text';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../contexts/responsive_tools';

function Desktop() {
    const location = useLocation();
    const navigate = useNavigate();
    const [state] = useState(location.state ? location.state : {});
    const { user, accountConfiguration, updateAccountConfiguration } = useAuth();
    const [disablePrompt, setDisablePrompt] = useState(false);
    const [shortPathCopied, setShortPathCopied] = useState(false);
    const [text, setText] = useState('');
    const [errors, setErrors] = useState([]);
    const [domains, setDomains] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [creatorIdentifier, setCreatorIdentifier] = useState(state?.creator_identifier || accountConfiguration['creatorIdentifier']);
    const [selectedDomain, setSelectedDomain] = useState(state?.domain_name || accountConfiguration['domain']);
    const [selectedProfile, setSelectedProfile] = useState(state?.profile_title || accountConfiguration['profile']);
    const [mode, setMode] = useState('search');
    const [currentThread, setCurrentThread] = useState();
    const [chatHistories, setChatHistories] = useState([]);
    const [withOpacity, setWithOpacity] = useState(true);
    const [loading, setLoading] = useState(false);
    const [chatTitlesLoading, setChatTitlesLoading] = useState(false);
    const [chatTitles, setChatTitles] = useState([]);
    const [newTitle, setNewTitle] = useState(null);
    const textAreaRef = useRef();
    const textAreaMobileRef = useRef();

    const endOfMessagesRef = useRef();
    const endOfMessagesMobileRef = useRef();

    const modeChange = (value) => {
        setMode(value);
    }

    const parseChatHistory = (content) => {
        return {
            id: content.id,
            status: content.status,
            text: content.text,
            system: content.system,
            creator_identifier: content.responses[0]?.creator?.identifier,
            domain_name: content.responses[0]?.domain?.name,
            profile_title: content.responses[0]?.profile?.title,
            response: content.responses[0]?.text
        }
    }

    const parseChatTitleHistory = (content) => {
        return {
            id: content.id,
            title: content.text,
            short_title: content.short_title,
            public: content.public,
            short_path: content.short_path
        }
    }

    const parseChatTitles = useCallback((contents) => {
        const chatTitles = [];

        contents = contents.reverse();
        contents.forEach((content) => {
            const chatTitle = parseChatTitleHistory(content);
            chatTitles.push(chatTitle);
        });

        return chatTitles;
    }, [])

    const parseChatHistories = useCallback((contents) => {
        const chatHistories = [];

        contents = contents.reverse();
        contents.forEach((content) => {
            const chatHistory = parseChatHistory(content);
            chatHistories.push(chatHistory);
        });

        return chatHistories;
    }, [])

    const fetchContentViewThread = useCallback(() => {
        if (currentThread) {
            setLoading(true);
            contentViewThreadRequest(currentThread, 1, 999).then((response) => {
                const chats = parseChatHistories(response.data.data);
                setChatHistories(chats);
                scrollUpdate();
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [currentThread, parseChatHistories])

    const fetchContentViewThreadTitles = useCallback(() => {
        setChatTitlesLoading(true);
        contentViewThreadTitlesRequest(user.id, 1, 999).then((response) => {
            const titles = parseChatTitles(response.data.data);
            setChatTitles(titles);
            setChatTitlesLoading(false);
        }).catch((error) => {
            console.log(error);
        })
    }, [parseChatTitles, user])

    const fetchUpdateCurrentContentView = async (id) => {
        contentViewsShowRequest(id).then((response) => {
            if (response.data.status !== 'pending') {
                const chatHistory = parseChatHistory(response.data);
                setChatHistories([...chatHistories, chatHistory]);
                scrollUpdate();

                setDisablePrompt(false);

                setTimeout(() => {
                    textAreaRef.current.focus();
                    textAreaMobileRef.current.focus();
                }, 100);
            } else {
                setTimeout(() => {
                    fetchUpdateCurrentContentView(id);
                }, 500);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const submitRequest = () => {
        setDisablePrompt(true);

        var body = {
            text: text,
            profile_title: selectedProfile,
            content_view_id: currentThread,
            account_id: user.id,
            kind: mode,
            from_playground: true,
            attrs: [{
                domain_name: selectedDomain,
                profile_title: selectedProfile,
                creator_identifier: creatorIdentifier
            }]
        }

        if (!currentThread) {
            body['thread'] = true;
        }

        contentViewsCreateRequest(body).then((response) => {
            const data = response.data;

            if (!currentThread) {
                setCurrentThread(data.id);
            }

            setText('');

            if (body.thread) {
                setChatTitles([parseChatTitleHistory(data), ...chatTitles]);
            }

            setChatHistories([...chatHistories, parseChatHistory(data)]);
            fetchUpdateCurrentContentView(data.id);
            scrollUpdate()
        }).catch((error) => {
            console.log(error);
            setDisablePrompt(false);
        });
    }

    const handlerKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            submitRequest();
        } else {
            return;
        }
    }

    const goTo = (e, path) => {
        e.preventDefault();
        navigate(path);
    }

    const chooseDomain = (value) => {
        if (value) {
            setSelectedDomain(value);
            updateAccountConfiguration({ ...accountConfiguration, domain: value });
        }
    }

    const chooseProfile = (value) => {
        const profile = profiles.find((profile) => profile.title === value);
        setCreatorIdentifier(profile.creator.identifier);
        setSelectedProfile(value);
        updateAccountConfiguration({ ...accountConfiguration, profile: value, creatorIdentifier: profile.creator.identifier});
    }

    const clearConfigs = () => {
        setSelectedDomain('');
        setSelectedProfile('');

        updateAccountConfiguration({ ...accountConfiguration, domain: '', profile: '' });
    }

    const changeChatHistory = (e) => {
        e.preventDefault();
        const id = e.currentTarget.value;
        setChatHistories([]);
        setShortPathCopied(false);
        setNewTitle(null);
        setCurrentThread(id);
    }

    useEffect(() => {
        if (state.domain_name) {
            setSelectedDomain(state.domain_name);
        } else {
            setSelectedDomain(accountConfiguration['domain']);
        }

        if (state.profile_title) {
            setSelectedProfile(state.profile_title);
        } else {
            setSelectedProfile(accountConfiguration['profile']);
        }

        if (state.creator_identifier) {
            setCreatorIdentifier(state.creator_identifier);
        } else {
            setCreatorIdentifier(accountConfiguration['creatorIdentifier']);
        }
    }, [accountConfiguration, state])

    useEffect(() => {
        fetchContentViewThread()
    }, [fetchContentViewThread, currentThread])

    useEffect(() => {
        fetchContentViewThreadTitles()
    }, [fetchContentViewThreadTitles])

    useEffect(() => {
        const fetchDomais = async () => {
            accountsDomainAllRequest(user.id, 1, 999).then((response) => {
                const domains = response.data.data.map((attributes) => { return attributes.domain });
                setDomains(domains);
            }).catch((error) => {
                console.log(error);
            })
        }

        fetchDomais();
    }, [user]);

    useEffect(() => {
        const fetchProfiles = async () => {
            profileShowDomainRequest(selectedDomain, 1, 999).then((response) => {
                setProfiles(response.data.data);
            }).catch((error) => {
                console.log(error);
            })
        }

        if (selectedDomain) {
            fetchProfiles()
        }
    }, [user, selectedDomain]);

    const changeText = (e) => {
        setText(e.target.value)
    }

    const scrollUpdate = () => {
        [endOfMessagesRef, endOfMessagesMobileRef].forEach((ref) => {
            const scrollElement = ref.current;
            if (scrollElement) {
                setTimeout(() => {
                    scrollElement.scrollTop = scrollElement.scrollHeight;
                    setLoading(false);

                    setTimeout(() => {
                        setWithOpacity(false);
                    }, 50);
                }, 50);
            }
        });
    }

    const contentViewArchive = (e, id) => {
        e.preventDefault();

        contentViewArchiveRequest(id).then((_) => {
            setChatTitles(
                chatTitles.filter((title) => title.id !== id)
            );

            setChatHistories([]);
            setShortPathCopied(false);
            setCurrentThread(null);
        }).catch((error) => {
            setErrors(['Erro ao deletar o chat, tente novamente mais tarde.']);
        });
    }

    const contentViewMarkAsPublic = (e, chatTitle) => {
        if (chatTitle.public) {
            console.log('isPublic');
        } else {
            contentViewMarkAsPublicRequest(chatTitle.id).then((response) => {
                setChatTitles(
                    chatTitles.map((title) => {
                        if (title.id === chatTitle.id) {
                            title.public = true;
                            title.short_path = response.data.short_path;
                        }

                        return title;
                    })
                );
            }).catch((error) => {
                setErrors(['Erro ao tornar o chat público, tente novamente mais tarde.']);
            });
        }
    }

    const contentViewMarkAsPrivate = (e, chatTitle) => {
        if (chatTitle.public) {
            contentViewMarkAsPrivateRequest(chatTitle.id).then((response) => {
                setChatTitles(
                    chatTitles.map((title) => {
                        if (title.id === chatTitle.id) {
                            title.public = false;
                            title.short_path = null;
                        }

                        return title;
                    })
                );
            }).catch((error) => {
                setErrors(['Erro ao tornar o chat privado, tente novamente mais tarde.']);
            });
        } else {
            console.log('isPrivate');
        }
    }

    const copyToClipboard = (e, value) => {
        e.preventDefault();
        navigator.clipboard.writeText(value).then(() => {
            setShortPathCopied(true);
        }).catch((error) => {
            setShortPathCopied(false);
        })
    }

    const contentViewUpdate = (e, chatTitle, attributes) => {
        contentViewUpdateRequest(chatTitle.id, attributes).then((response) => {
            setChatTitles(
                chatTitles.map((title) => {
                    if (title.id === chatTitle.id) {
                        title.short_title = response.data.short_title;
                    }

                    return title;
                })
            );
        }).catch((error) => {
            setErrors(['Erro ao atualizar o título do chat, tente novamente mais tarde.']);
        });
    }

    const setNewTitleDialog = (chatTitle) => {
        const changeTitle = (e) => {
            setNewTitle(e.target.value);
        }

        return (
            <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Novo Título</AlertDialog.Title>
                <AlertDialog.Description size="3">
                    <TextField.Root value={newTitle} size="2" onChange={changeTitle} />
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Action>
                        <Button variant="solid">Fechar</Button>
                    </AlertDialog.Action>
                    <AlertDialog.Action>
                        <Button variant="solid" color="green" onClick={(e) => { contentViewUpdate(e, chatTitle, { short_title: newTitle }) }}>
                            Atualizar
                        </Button>
                    </AlertDialog.Action>
                </Flex>
            </AlertDialog.Content>
        )
    }

    const shareAlertDialog = (chatTitle) => {
        const host = window.location.host;
        const path = `${host}/pergunta/${chatTitle.short_path}`;

        return (
            <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Link Público</AlertDialog.Title>
                <AlertDialog.Description size="3">
                    <Box>
                        <TextField.Root disabled={true} value={path} size="2">
                            <TextField.Slot>
                                <Share1Icon height="16" width="16" />
                            </TextField.Slot>

                            <TextField.Slot>
                                {
                                    shortPathCopied ?
                                        <Text color='green'>Copiado</Text> :
                                        null
                                }
                                <IconButton size="1" variant="ghost" onClick={(e) => {copyToClipboard(e, path)}}>
                                    {
                                        shortPathCopied ?
                                            <CheckCircledIcon height='14' width='14' color='green' /> :
                                            <CopyIcon height="14" width="14" />
                                    }
                                </IconButton>
                            </TextField.Slot>
                        </TextField.Root>
                    </Box>
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Action>
                        <Button variant="solid">Fechar</Button>
                    </AlertDialog.Action>
                    <AlertDialog.Action>
                        <Button variant="solid" color="red" onClick={(e) => { contentViewMarkAsPrivate(e, chatTitle) }}>
                            <LockClosedIcon />
                            Tornar privado
                        </Button>
                    </AlertDialog.Action>
                </Flex>
            </AlertDialog.Content>
        )
    }

    const dropDownTitle = (chatTitle) => {
        return (
            <Flex gap="2" mx='2'>
                <AlertDialog.Root>
                    <AlertDialog.Trigger>
                        <IconButton size='1' color="indigo" variant={chatTitle.public ? "solid" : "soft"} onClick={(e) => contentViewMarkAsPublic(e, chatTitle)}>
                            <Share1Icon width="15" height="15" />
                        </IconButton>
                    </AlertDialog.Trigger>

                    {shareAlertDialog(chatTitle)}
                </AlertDialog.Root>

                <AlertDialog.Root>
                    <AlertDialog.Trigger>
                        <IconButton size='1' color="indigo" variant="soft">
                            <Pencil1Icon width="15" height="15" />
                        </IconButton>
                    </AlertDialog.Trigger>

                    {setNewTitleDialog(chatTitle)}
                </AlertDialog.Root>

                <IconButton size='1' color="crimson" variant="soft" onClick={(e) => contentViewArchive(e, chatTitle.id)}>
                    <TrashIcon width="15" height="15" />
                </IconButton>
            </Flex>
        )
    }

    const chatHistoriesHtml = () => {
        return chatHistories.map((chatHistory) => {
            return (
                <Box key={chatHistory.id}>
                    {!chatHistory.system && <Flex direction='row-reverse' mx='4'>
                        <HoverCard.Root>
                            <HoverCard.Trigger>
                                <Callout.Root style={{maxWidth: '50%'}}>
                                    <Callout.Text>
                                        <Strong>
                                            <MarkdownText text={chatHistory.text} />
                                        </Strong>
                                    </Callout.Text>
                                </Callout.Root>
                            </HoverCard.Trigger>
                            <HoverCard.Content maxWidth="300px">
                                {<Flex gap='2' align='center'>
                                    <DataList.Root>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">Domínio</DataList.Label>
                                            <DataList.Value>
                                                <Badge color="blue">{chatHistory.domain_name || 'Nenhum foi selecionado'}</Badge>
                                            </DataList.Value>
                                        </DataList.Item>

                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">Perfil</DataList.Label>
                                            <DataList.Value>
                                            <Badge color="green">{chatHistory.profile_title || 'Nenhum foi selecionado'}</Badge>
                                            </DataList.Value>
                                        </DataList.Item>
                                    </DataList.Root>
                                </Flex>}
                            </HoverCard.Content>
                        </HoverCard.Root>
                    </Flex>}

                    <Container size='2'>
                        <MarkdownText text={chatHistory.response} system={chatHistory.system} />
                    </Container>
                </Box>
            )
        })
    }

    const filterComponents = () => {
        return (<>
            <Text>Domínio</Text>
            <Select.Root onValueChange={chooseDomain} defaultValue={selectedDomain} value={selectedDomain}>
                <Select.Trigger placeholder="Selecione um domínio" disabled={domains.length === 0} />
                <Select.Content>
                    <Select.Group>
                        {domains.length > 0 && domains.map((domain) => {
                            return <Select.Item key={domain.id} value={domain.name}>{domain.name}</Select.Item>
                        })}
                    </Select.Group>
                    <Select.Separator />
                    <Flex align='center' justify='center'>
                        <Button onClick={(e) => {goTo(e, '/dominios/novo')}}>
                            <Text>Criar um novo</Text>
                        </Button>
                    </Flex>
                </Select.Content>
            </Select.Root>

            <Text>Perfil</Text>
            <Select.Root onValueChange={chooseProfile} defaultValue={selectedProfile} value={selectedProfile}>
                <Select.Trigger placeholder="Selecione um perfil" disabled={profiles.length === 0} />
                <Select.Content>
                    <Select.Group>
                        {profiles.length > 0 && profiles.map((profile) => {
                            return <Select.Item key={profile.id} value={profile.title}>{profile.title}</Select.Item>
                        })}
                    </Select.Group>
                    <Select.Separator />
                    <Flex align='center' justify='center'>
                        <Button onClick={(e) => {goTo(e, '/perfis/novo')}}>
                            <Text>Criar um novo</Text>
                        </Button>
                    </Flex>
                </Select.Content>
            </Select.Root>

            <Button variant='soft' color='gray' onClick={clearConfigs}>
                <TrashIcon />
                <Text>Limpar</Text>
            </Button>

            <Text>Modo</Text>
            <SegmentedControl.Root defaultValue={mode} onValueChange={modeChange}>
                <SegmentedControl.Item value="search">Pesquisa</SegmentedControl.Item>
                <SegmentedControl.Item value="recommendation">Sugestões</SegmentedControl.Item>
            </SegmentedControl.Root>
        </>)
    }

    const sideBar = () => {
        return (<>
            <Button
                onClick={changeChatHistory}
                value={null}
                style={{
                    padding: 'var(--space-5)',
                    width: '100%'
                }}>
                <ChatBubbleIcon />
                Criar novo CHAT
            </Button>
            <Separator orientation='horizontal' size='4' my='1' />

            <ScrollArea className='Menu' type="always" scrollbars="vertical" size='2' style={{ flex: 1, height: 100, paddingRight: '18px' }}>
                {chatTitlesLoading && <Flex justify='center' p='4'><Spinner size='3' /></Flex>}
                {!chatTitlesLoading && chatTitles.length > 0 &&
                    (<DataList.Root size='1' style={{ gap: '0' }}>
                        {chatTitles.map((chatTitle, index) => {
                            return (
                                <DataList.Item
                                    key={index}
                                    align="center"
                                    style={{backgroundColor: 'var(--gray-a8)'}}>
                                        <DataList.Label width='17vh'>
                                            <Button
                                                key={chatTitle.id}
                                                onClick={changeChatHistory}
                                                value={chatTitle.id}
                                                mb='0'
                                                disabled={Number(currentThread) === chatTitle.id}
                                                variant='soft'
                                                color='gray'
                                                radius='none'
                                                style={{
                                                    width: '100%',
                                                    padding: 'var(--space-5)',
                                                    textAlign: 'left',
                                                    justifyContent: 'flex-start'
                                                }}>
                                                    <Text truncate>{chatTitle.short_title || chatTitle.title}</Text>
                                            </Button>
                                        </DataList.Label>

                                        <DataList.Value style={{flexDirection: 'row-reverse'}}>
                                            { dropDownTitle(chatTitle) }
                                        </DataList.Value>
                                </DataList.Item>
                            )
                        })}
                    </DataList.Root>)
                }
            </ScrollArea>
        </>);
    }

    const chatArea = (messageRef, scrollRef) => {
        return (<>
            {loading && <Flex justify='center' p='8'><Spinner size='3' /></Flex>}
            <ScrollArea
                ref={scrollRef}
                className='ChatHistory'
                type="always"
                scrollbars="vertical"
                size='2'
                style={{
                    opacity: withOpacity ? 0 : 1,
                    width: '100%',
                    flex: 1,
                    minHeight: 0
                }}>
                    {chatHistoriesHtml()}
                    {disablePrompt && <Box mx='4'>
                            <Flex direction='row-reverse'>
                                <Spinner size='3' mt='2' />
                            </Flex>
                        </Box>
                    }
            </ScrollArea>

            <Flex
                direction='column'
                gap='2'
                align='start'
                style={{
                    maxHeight: '700px',
                    overflow: 'auto'
                }}>
                <Separator orientation='horizontal' size='4' />

                <Flex gap='2' align='center' justify='between' display={displayOnlyLaptopsAndDesktops(true)}>
                    {filterComponents()}
                </Flex>

                <Box display={displayOnlyMobiles()}>
                    <Popover.Root>
                        <Popover.Trigger>
                            <Button variant={selectedDomain && selectedProfile ? 'solid' : 'soft'}>
                                <MixerVerticalIcon />
                                Filtros
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content width="300px">
                            <Flex direction='column' mb='2' gap='2' align='stretch' justify='between'>
                                {filterComponents()}
                            </Flex>
                        </Popover.Content>
                    </Popover.Root>
                </Box>

                <Flex width='100%' style={{ position: 'relative' }}>
                    <TextArea
                        ref={messageRef}
                        value={text}
                        placeholder="Digite aqui o que você quer saber..."
                        autoComplete='off'
                        autoFocus={true}
                        resize='vertical'
                        size='3'
                        style={{
                            minHeight: '100px',
                            maxHeight: '300px',
                            height: '100px',
                            width: '100%'
                        }}
                        onChange={changeText}
                        onKeyDown={handlerKeyPress}
                        disabled={disablePrompt}
                    />
                    <Button
                        loading={disablePrompt}
                        disabled={disablePrompt || !text}
                        radius='full'
                        my='3'
                        mx='3'
                        onClick={submitRequest}
                        style={{
                            right: '2px',
                            bottom: '-5px',
                            position: 'absolute'
                        }}>
                            <ArrowUpIcon />
                    </Button>
                </Flex>
            </Flex>
        </>)
    }

    return (
        <Flex minHeight='100vh' maxHeight='100vh' maxWidth='100%' style={{marginTop: '-56px', paddingTop: '56px'}}>
            <Toast.Provider swipeDirection="right">
                <Toast.Root className="ToastRoot" open={errors.length > 0}>
                    <Toast.Title className="ToastTitle">
                        <Flex gap='2' style={{alignItems: 'center', color: 'white'}}>
                            <InfoCircledIcon />
                            <Text weight="bold">Aconteceu algum erro</Text>
                        </Flex>
                    </Toast.Title>
                    <Toast.Description>
                        {errors.map((error, index) => (
                            <Text key={index} size='2' style={{color: 'white'}}>{error}</Text>
                        ))}
                    </Toast.Description>
                    <Toast.Action className="ToastAction" asChild altText="Goto schedule to undo">
                        <Button onClick={() => setErrors([])}>Fechar</Button>
                    </Toast.Action>
                </Toast.Root>
                <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>

            <Flex direction='column' p='2' style={{ backgroundColor: 'var(--gray-3)', width: '350px' }} display={displayOnlyLaptopsAndDesktops(true)}>
                {sideBar()}
            </Flex>

            <Flex direction='column' p='2' pl='3' style={{ backgroundColor: 'var(--gray-3)', width: '82%' }} display={displayOnlyLaptopsAndDesktops(true)}>
                {chatArea(textAreaRef, endOfMessagesRef)}
            </Flex>

            <Box display={displayOnlyMobiles()} p='2' style={{position: 'absolute', width: '100%', zIndex: '9999'}}>
                <Popover.Root>
                    <Popover.Trigger>
                        <Button variant='solid'>
                            <DoubleArrowDownIcon />
                            Menu
                        </Button>
                    </Popover.Trigger>
                    <Popover.Content width="100%" maxWidth='100%' style={{width: '100%', left: '-12px', top: '-8px'}}>
                        <Flex direction='column' gap='2' maxHeight='80vh' width='100%' maxWidth='100%' align='stretch' justify='between'>
                            {sideBar()}
                        </Flex>
                    </Popover.Content>
                </Popover.Root>
            </Box>

            <Flex direction='column' p='2' style={{ backgroundColor: 'var(--gray-3)', width: '100%' }} display={displayOnlyMobiles(true)}>
                {chatArea(textAreaMobileRef, endOfMessagesMobileRef)}
            </Flex>
        </Flex>
    );
}

export { Desktop };