import { Container, Heading } from '@radix-ui/themes';

function Home() {
    return (
        <Container size='3' p='2'>
            <Heading>Bem vindo ao Arbo!</Heading>
        </Container>
    );
}

export { Home };