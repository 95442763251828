import React, { useEffect, useState, useCallback } from 'react';
import { Container, Heading } from '@radix-ui/themes';
import { ScheduleIntegrationsForm } from './form';
import { useAuth } from '../../hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { scheduleIntegrationShowRequest, scheduleIntegrationUpdateRequest } from '../../api/schedule_integrations';

function ScheduleIntegrationEdit() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [integration, setIntegration] = useState({});
    const [errors] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleFrequencies = (frequency_hour, frequency_days_of_week) => {
        return `0 ${frequency_hour} * * ${frequency_days_of_week}`
    }

    const handleSubmit = async (e, formData) => {
        e.preventDefault();

        const body = {
            integration_id: formData.integration_id,
            account_id: user.id,
            cron: handleFrequencies(formData.frequency_hour, formData.frequency_days_of_week),
            configurations: JSON.stringify({
                text: formData.text,
                thread: formData.thread,
                domain_name: formData.domain_name,
                profile_title: formData.profile_title,
                creator_identifier: formData.creator_identifier,
                integration_event_identifier: formData.integration_event_identifier
            })
        }

        scheduleIntegrationUpdateRequest(id, body).then(response => {
            navigate('/acoes');
        }).catch(error => {
            console.log(error);
        });
    }

    const fetchScheduleIntegration = useCallback(async () => {
        scheduleIntegrationShowRequest(id).then(response => {
            setLoading(false);

            const configuration = JSON.parse(response.data.configurations);
            setIntegration({
                integration_id: response.data.integration.id,
                frequency_hour: response.data.cron.split(' ')[1],
                frequency_days_of_week: response.data.cron.split(' ')[4],
                text: configuration.text,
                thread: configuration.thread === null ? false: configuration.thread,
                domain_name: configuration.domain_name,
                profile_title: configuration.profile_title,
                creator_identifier: configuration.creator_identifier,
                integration_event_identifier: configuration.integration_event_identifier
            });
        }).catch(error => {
            console.log(error);
        });
    }, [id]);

    useEffect(() => {
        fetchScheduleIntegration();
    }, [fetchScheduleIntegration]);

    return (
        <Container size='3' mt='6'>
            <Heading>Inserir nova ação</Heading>
            <ScheduleIntegrationsForm
                errors={errors}
                integration={integration}
                handleSubmit={handleSubmit}
                loading={loading}/>
        </Container>
    );
}

export { ScheduleIntegrationEdit }